import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { globalGetService, globalExportService } from '../../../utils/globalApiServices';
import { checkPermission, checkApiStatus, getLocalStorageInfo, downloadFileType, localTimeFn } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { ReportsTab, Activity } from '../components';
import { Pagination, FilterComponent, ExportManu, PageLoader, EmptyCollection } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { getAllAssetListAc } from '../../../shared_elements/actionCreators';
import { Table, TableBody, Paper, Grid, Button, TextField, TableHead, TableRow, TableCell } from '@material-ui/core';
import config from '../../../config';
import { activitiesFilterOps } from '../';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
const ActivityHd = () => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>Activity Info</TableCell>
                <TableCell>Asset</TableCell>
                <TableCell>Performed By</TableCell>
                <TableCell>Performed At</TableCell>
                <TableCell>Device IP</TableCell>
                <TableCell>Device Type</TableCell>
                <TableCell>Browser Type</TableCell>
                <TableCell>OS Type</TableCell>
            </TableRow>
        </TableHead>
    )
}
const Activities = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [sortInfo, setSortInfo] = useState({sort:'', sort_by:''});
    const [filter, setFilter] = useState({});
    const [activityInfo, setActivityInfo] = useState({list:[], pagination:{}});
    const [activityTypes, setActivityTypes] = useState([]);
    const allAssets = useSelector(state => state?.shareReducer?.allAssets||[])
    const dispatch = useDispatch()
    useEffect(() => {
        getActivities({}, 'skeletonLoader');
        getActivityTypes();
        dispatch(getAllAssetListAc());
        trackActivity('Page Visited', {
            page_title: 'Activities'
        })
    },[]);
    const getActivityTypes = () => {
        globalGetService(`records/v2/get-activity-list/`)
        .then(response => {
            if(checkApiStatus(response)){
                setActivityTypes(response.data.data)
            }
        })
    }
    const getActivities = (query, loaderType) => {
        let queryParams = Object.assign({}, query);
        if(queryParams.asset){
            let filterAsset = queryParams.asset.split('_');
            queryParams = {
                ...queryParams,
                asset_type:filterAsset[1],
                asset_id:filterAsset[0]
            }
            delete queryParams.asset
        }
        loaderType === 'skeletonLoader'?setSkeletonLoader(true):setLoading(true);
        globalGetService(`records/v2/report/activity/`, queryParams)
        .then(response => {
            if(checkApiStatus(response)){
                setActivityInfo(response.data.data);
                delete query.page;
                delete query.per_page;
                setFilter(query);
            }
            loaderType === 'skeletonLoader'?setSkeletonLoader(false):setLoading(false);
        })
    }
    const exportActivities = (query) => {
        setLoading(true);
        let queryParams = Object.assign({}, query);
        if(queryParams.asset){
            let filterAsset = queryParams.asset.split('_');
            queryParams = {
                ...queryParams,
                asset_type:filterAsset[1],
                asset_id:filterAsset[0]
            }
            delete query.asset
        }
        globalGetService('records/v2/report/activity/', {...queryParams})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}})
            }else{
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}})
            }
            setLoading(false)
        })
    }
    let filterOptions = activitiesFilterOps;
    filterOptions = {
      ...filterOptions,
      asset: {
        ...filterOptions.asset,
        options: allAssets.sort((a, b) => a.unique_name !== b.unique_name ? a.unique_name < b.unique_name ? -1 : 1 : 0).map((item) => { return { ...item, id: `${item.id}_${item.asset_type}` } })
      },
      activity_type: {
        ...filterOptions.activity_type,
        options: activityTypes
      }
    }
    return(
        <section className='fleets-management'>
            <DeploymentMessage />
            <ReportsTab tabIndex="activities" />
            <div>
                { skeletonLoader ? <STableLoader count={9} />:
                    <>
                        <Paper square style={{padding:'5px 10px'}}>
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <FilterComponent 
                                        filter={filter} 
                                        filterMenu={filterOptions}
                                        getResponseBack={(applyFilter) => getActivities({...applyFilter, page:1, per_page:activityInfo.pagination.per_page}, 'pageLoader')}
                                    />
                                </Grid>
                                { checkPermission('reports','activities','EXP') ?
                                    <Grid item xs={4}>
                                        <ul className='list-inline' style={{float:'right'}}>
                                            <li className='list-inline-item'>
                                                <ExportManu 
                                                    exportReportFn={(file) => exportActivities({...filter, download:file.extension})}
                                                    files={[{title:'EXCEL', extension:'xlsx'}]}
                                                />
                                            </li>
                                        </ul>
                                    </Grid>:null
                                }
                            </Grid>
                        </Paper>
                        <Paper className='major-assemblies-list'>
                            <div style={{height: !activityInfo.list.length ? 'auto':`${window.innerHeight-240}px`,overflow:'auto'}}>
                                <Table className='mui-table-format' stickyHeader={true}>
                                    <ActivityHd />
                                    <TableBody>
                                        {activityInfo.list.map((item,index) =>
                                            <Activity item={item} />
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <Pagination 
                                pagination={activityInfo.pagination}
                                onChangePage={(event, newPage) => getActivities({...filter, page:newPage+1, per_page: activityInfo.pagination.per_page, sort_by:sortInfo.sort_by, sort:sortInfo.sort}, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getActivities({...filter, page:1, per_page: event.target.value, sort_by:sortInfo.sort_by, sort:sortInfo.sort}, 'pageLoader')}
                            />
                            {!activityInfo.list.length ? <div style={{textAlign:'center'}}><EmptyCollection title={'No records found'} /></div>:null}
                        </Paper>   
                    </>
                }
            </div>
            { isLoading ? <PageLoader/>:null}
        </section>
    )
}
export default Activities;