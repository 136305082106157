import moment from 'moment';
import { globalGetService, globalPostService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, getLocalStorageInfo, downloadFileType } from '../../utils';
import { trackActivity } from '../../utils/mixpanel';
export function getOCRPriorityAssetsApi(props, queryParams = {}) {
  this.setState({ pageLoader: true });
  globalGetService('console/records-asset-list/?lite=1', window.location.href.includes('archived') ? { ocr_priority: 'all' , archive_status: 'True'} : { ...queryParams,ocr_priority: 'all' })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ assets: response.data.data, modal: true });
      }
    })
}
export function changeOCRPriorityAssetApi(props, ocrPriority = {}, filter) {
  if (ocrPriority.data) {
    this.setState({ pageLoader: true });
    let payload = {};
    payload = {
      ...payload,
      asset_details: [{ asset_type: ocrPriority.asset.asset_type, asset_id: ocrPriority.asset.id }],
      ocr_priority: ocrPriority.data.value
    }
    globalPostService('records/v2/change-ocr-priority/', payload)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.setState({ ocrPriority: { modal: false, asset: null, data: null } });
          this.getAssetListApi(this.props, {...filter, per_page: this.state.assetList?.pagination?.per_page||50}, 'pageLoader')
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  } else {
    this.props.enqueueSnackbar("Please select the Priority", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
  }
}
export function getAssetListApi(props, queryParams = {}, loaderType) {
  this.setState({ [loaderType]: true });
  globalGetService('console/records-asset-list/?lite=1', window.location.href.includes('archived') ? {...queryParams, archive_status: 'True', billable_asset:'true'} : {...queryParams, billable_asset:'true'})
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        this.setState({ sr_filter: queryParams?.serial_number ? true: false });
        delete queryParams.sort;
        delete queryParams.sort_by;
        delete queryParams.page;
        delete queryParams.per_page;
        delete queryParams.asset_type;
        delete queryParams.serial_number;
        delete queryParams.all_assets;
        this.setState({ assetList: response.data.data, filter: queryParams });
      }
    })
}
export function addNewAssetApi(props, url, data) {
  return new Promise(function (resolve, reject) {
    globalPostService(url, data)
      .then(response => {
        if (checkApiStatus(response)) {

        }
        resolve(response);
      })
  })
}
export function exportAssetListApi(props, queryParams = {}, loaderType) {
  this.setState({ pageLoader: true });
  globalExportService('console/records-asset-list/', window.location.href.includes('archived') ? {...queryParams, archive_status: 'True'} : queryParams)
    .then(response => {
      this.setState({ pageLoader: false });
      if(getLocalStorageInfo().defaultLessor.id===442  && queryParams.records_download == 'xlsx'){
        this.props.enqueueSnackbar("Download link for Report will be sent to your registered email", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
     }else if (getLocalStorageInfo().defaultLessor.id===442  && queryParams.records_download == 'pdf') {
      downloadFileType(response.data, (`${getLocalStorageInfo().defaultLessor.name}_Records_Assets`), queryParams.records_download);
      this.props.enqueueSnackbar("File has been Exported Successfully", {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
     downloadFileType(response.data, (`${getLocalStorageInfo().defaultLessor.name}_Records_Assets`), queryParams.records_download);
     this.props.enqueueSnackbar("File has been Exported Successfully", {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
   }
      trackActivity('Item Exported', {
        page_title: 'Asset Listing',
        event_type: 'Export',
        event_desc: 'Exported Asset List data from Asset Listing',
        extension: queryParams.records_download
      })
    })
}
export function getOwnersApi() {
  globalGetService(`console/owner/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          ownersList: response.data.data
        })
      }
    })
}

export const archiveAssetApi = (type, slug) => {
  globalGetService(`/console/archive-asset/${type}/${slug}/true/`)
  .then(response => {
    if(checkApiStatus(response)){
      window.location.href = "/archived-assets"
    }
  })
}
