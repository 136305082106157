import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
const ChangeOCRPriority = ({ocrPriority, onSelectPriority, changeOCRPriorityAsset, toggleModalFn, assetType}) => {
  return(
    <Dialog
      open={ocrPriority.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
      fullWidth= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        Change {assetType?assetType:'Asset'} OCR Priority
      </DialogTitle>
      <DialogContent dividers={true} className="data-upload-modal">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options = {[{label:'High',value:1}, {label:'Medium',value:2}, {label:'Low',value:3},{label:'No Priority',value:4}]}
              getOptionLabel={option => option.label}
              id="ocr_priority"
              value={ocrPriority.data}
              onChange={(e, value) => onSelectPriority(value)}
              renderInput={params => <TextField required {...params} label="Select Priority" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={toggleModalFn}>Cancel</Button>
        <Button color="primary" onClick={changeOCRPriorityAsset} variant="contained">Save</Button>
      </DialogActions>
    </Dialog>
  )
}
export default ChangeOCRPriority;
