import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, CircularProgress } from '@material-ui/core'
import { checkApiStatus, checkPermission } from '../../../utils'
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices'
import { useSnackbar } from 'notistack'
import { CkEditorText, DeletePopUp, EmptyCollection, PageLoader, Pagination } from '../../../shared_elements'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { Autocomplete } from '@material-ui/lab'
import { Edit } from '@material-ui/icons'
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment'

const maAssets = [{ label: 'Engine', value: 2 }, { label: 'APU', value: 3 }, { label: 'Landing Gear', value: 4 }, { label: 'Propeller', value: 5 }]

const ConfigHd = () => {
    return (
        <TableHead style={{ position: "sticky", top: '0' }}>
            <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Major Assembly Asset Type</TableCell>
                <TableCell>Major Assembly Asset </TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Updated By</TableCell>
                <TableCell align='right'>Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const ConfigListing = ({ item, onDelete, setAddEditConfig, props }) => {
    return (
        <TableRow >
            <TableCell>
                <div > {item?.title || '--'}</div>
            </TableCell>
            <TableCell>
                <div > {item?.major_assembly_asset_type?.label || '--'}</div>
            </TableCell>
            <TableCell>
                <div > {item?.major_assembly_asset?.name || '--'}</div>
            </TableCell>
            <TableCell>
                <div >
                    {item?.created_by?.name || '--'}
                    <div style={{ fontSize: '11px' }}>
                        {item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY, hh:mm a') : null}
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <div>
                    {item?.updated_by?.name || '--'}
                    <div style={{ fontSize: '11px' }}>
                        {item?.updated_at ? moment(item?.updated_at).format('DD-MM-YYYY, hh:mm a') : null}
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <ul className='list-inline' style={{ float: 'right' }}>
                    <li className='list-inline-item' style={{ cursor: 'pointer', marginRight: '3px' }}>
                        <Tooltip title='Open Section' arrow placement='top'>
                            <Link to={`/${props.match.params?.assetType}/${props.match.params?.assetSlug}/data-room-mini-pack/${item?.slug}/${item?.id}`}>
                                <OpenInNewIcon fontSize='small' color='primary' />
                            </Link>
                        </Tooltip>
                    </li>
                    {checkPermission('records', 'mini-pack', 'U') ?
                    <li className='list-inline-item' style={{ cursor: 'pointer', marginRight: '3px' }}>
                        <Tooltip title='Edit Minipack' arrow placement='top'>
                            <Edit onClick={() => { setAddEditConfig(item) }} fontSize='small' color='primary' />
                        </Tooltip>
                    </li>
                    : null}
                    {checkPermission('records', 'mini-pack', 'U') ?
                        <li className='list-inline-item' style={{ cursor: 'pointer' }}>
                            <Tooltip arrow title='Delete Minipack' placement='top'>
                                <DeleteOutlineIcon onClick={() => onDelete()} fontSize='small' color='secondary' />
                            </Tooltip>
                        </li>
                        : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditConfig = ({ toggleModalFn, saveConfig, addEditConfig, props, error, setError }) => {
    const [configData, setConfigData] = useState(addEditConfig?.data || {})
    const [configName, setConfigName] = useState(addEditConfig?.data?.config || null)
    const [maTypes, setMAType] = useState([])
    const [assemblyLoader, setAssemblyLoader] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [configOptions, setConfigOptions] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        getConfigOptions()
        if (addEditConfig?.data?.id && addEditConfig?.data?.major_assembly_asset_type?.value) {
            getMajorAssembly(addEditConfig?.data?.major_assembly_asset_type?.value, 'pageLoader')
        }
    }, [])
    const onFieldChange = (key, value) => {
        if (key == 'major_assembly_asset_type') {
            if (value) { getMajorAssembly(value?.value) }
            setConfigData({ ...configData, [key]: value, major_assembly_asset: null, })
        }
        else {
            setConfigData({ ...configData, [key]: value })
        }
    }
    const getMajorAssembly = (assetType, loaderType) => {
        setMAType([])
        setAssemblyLoader(true)
        if (loaderType == 'pageLoader') {
            setLoading(true)
        }
        globalGetService(`console/v2/aircraft/${props?.match?.params?.assetSlug}/major-assemblies/`, { asset_type: assetType })
            .then(response => {
                setAssemblyLoader(false)
                setLoading(false)
                if (checkApiStatus(response)) {
                    setMAType(response.data.data?.list)
                }
            })
    }
    const getConfigOptions = () => {
        globalGetService(`records/v2/minipack/config/`, { dropdown: true, status: 1 })
            .then(response => {
                if (checkApiStatus(response)) {
                    setConfigOptions(response.data.data?.list)
                }
                else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }

    return (
        <>
            {assemblyLoader && addEditConfig?.data?.id ? <PageLoader /> : null}

            <Dialog
                open={true}
                onClose={toggleModalFn}
                className='records-dataroom-modal'
                maxWidth="sm"
            >
                <DialogTitle>
                    {addEditConfig?.data ? 'Edit' : 'Add'}  Minipack
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                required
                                label="Title"
                                name="title"
                                fullWidth
                                margin="normal"
                                value={configData?.title || ''}
                                error={error?.title}
                                helperText={error?.title || ''}
                                inputProps={{ maxLength: 250 }}
                                onChange={(e) => onFieldChange('title', e.target.value)}
                                onFocus={() => setError({ ...error, title: '' })}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                placeholder="Please select Minipack Title"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                disabled={configData?.id ? true : false}
                                style={{ paddingTop: "13px", marginBottom: '10px' }}
                                options={configOptions}
                                getOptionLabel={option => option.name}
                                value={configName || null}
                                onChange={(e, value) => setConfigName(value)}
                                renderInput={params => <TextField required variant='outlined' onFocus={() => setError({ ...error, configName: '' })} error={error?.configName} helperText={error?.configName || ''} placeholder="Please select Config Minipack" size='small' {...params} margin="none" label='Config Mini-Pack' fullWidth InputLabelProps={{ shrink: true }} />}
                            />
                        </Grid>
                        {props?.match?.params?.assetType == 'aircraft' ?
                        <Grid item xs={12}>
                            <Autocomplete
                                style={{ paddingTop: "13px", marginBottom: '10px' }}
                                options={maAssets}
                                getOptionLabel={option => option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={maAssets.find(item => item?.value == configData?.major_assembly_asset_type?.value) || null}
                                onChange={(e, data) => onFieldChange('major_assembly_asset_type', data)}
                                renderInput={params => <TextField variant='outlined' placeholder="Please select Major Assembly Type" size='small' {...params} margin="none" label='Major Assembly Type' fullWidth InputLabelProps={{ shrink: true }} />}
                            />
                        </Grid>: null}
                        {props?.match?.params?.assetType == 'aircraft' ?
                        <Grid item xs={12}>
                            <Autocomplete
                                style={{ paddingTop: "13px", marginBottom: '10px' }}
                                options={maTypes || []}
                                disabled={configData?.major_assembly_asset_type ? false : true}
                                getOptionLabel={option => option.major_assembly_name}
                                value={maTypes?.find(item => item?.id == configData?.major_assembly_asset?.id) || null}
                                onChange={(e, data) => onFieldChange('major_assembly_asset', data)}
                                renderInput={params => <TextField variant='outlined' onFocus={() => setError({ ...error, major_assembly_asset: '' })} error={error?.major_assembly_asset} helperText={error?.major_assembly_asset || ''} placeholder={assemblyLoader ? 'Fetching the List...' : "Please select Major Assembly Asset"} size='small' {...params} margin="none" label='Major Assembly Asset' fullWidth InputLabelProps={{ shrink: true }} />}
                            />
                        </Grid> : null}
                        <Grid item xs={12} sm={12} md={12}>
                            <label style={{ fontSize: '11px', color: "#000000", marginLeft: "15px" }} className='for-label mui-label-type'>Introduction </label>
                            <CkEditorText
                                disabled={false}
                                htmlData={configData?.introduction || ''}
                                onChangeData={(data) => { onFieldChange('introduction', data); setError({ ...error, introduction: '' }) }}
                            />
                            {error?.introduction?.trim()?.length ? <p className='error-msg'>{error.introduction}</p> : null}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ paddingRight: "23px" }}>
                    <Button variant='outlined' color='primary' size='small' onClick={toggleModalFn}>
                        Cancel
                    </Button>
                    <Button variant='contained' color='primary' size='small' onClick={() => saveConfig(configData, configName)}>
                        Save
                    </Button>
                    {addEditConfig?.data ? null :
                        <Button variant='contained' color='primary' size='small' onClick={() => saveConfig(configData, configName, 'sync')}>
                            Save & Sync
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}
function InitialConfig(props) {
    const [addEditConfig, setAddEditConfig] = useState({})
    const [configList, setConfigList] = useState([])
    const [deleteConfigModal, setDeleteConfigModal] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        getConfigList()
    }, [])
    const getConfigList = (query) => {
        setLoading(true)
        globalGetService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/`, query)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setConfigList(response.data.data)
                }
                else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    const saveConfigData = (data, configName, sync) => {
        let validateInput = {}
        validateInput = {
            ...validateInput,
            title: data?.title ? '' : 'Please enter Title',
            configName: configName?.name ? '' : data?.id ? '' : 'Please select Config Minipack',
        }
        if (Object.keys(validateInput).every(k => { return validateInput[k] == '' })) {
            let payload = Object.assign({}, data)
            payload = {
                ...payload,
                major_assembly_asset_type: payload?.major_assembly_asset_type?.value ? payload?.major_assembly_asset_type : null,
                major_assembly_asset: payload?.major_assembly_asset ? payload?.major_assembly_asset : null
            }
            delete payload['config']
            setLoading(true)
            if (payload?.id) {
                globalPutService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${payload?.id}/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            setAddEditConfig(false)
                            getConfigList()
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                        else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    })
            }
            else {
                globalPostService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            saveInitialConfig(configName, sync, response?.data?.data)
                            setAddEditConfig(false)
                            getConfigList()
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                        else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    })
            }
        }
        else {
            setError(validateInput)
        }
    }
    const saveInitialConfig = (config, sync, configId) => {
        let payload = {}
        payload = {
            ...payload,
            config: config,
            apply_sync: sync == 'sync' ? true : false
        }
        setLoading(true)
        globalPostService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${configId?.id}/minipack-section/`, payload)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    window.open(`/${props.match.params?.assetType}/${props.match.params?.assetSlug}/data-room-mini-pack/${configId?.slug}/${configId?.id}`, '_self')
                    // getResponseBack()
                }
            })
    }
    const onDeleteConfig = () => {
        setLoading(true)
        globalDeleteService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${deleteConfigModal?.data?.id}/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    getConfigList()
                    setDeleteConfigModal(false)
                }
                else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            }
            )
    }
    console.log('props',props);
    return (
        <Paper>
            <Grid container spacing={1}>
                <Grid item sm={6}></Grid>
                <Grid item sm={6}>
                    <Button style={{ float: "right", margin: "15px 10px" }} disabled={checkPermission('records', 'mini-pack', 'C') ? false : true} variant='contained' color='primary' size='small' onClick={() => { setError({}); setAddEditConfig({ modal: true }) }}>
                        Add Mini-Pack
                    </Button>
                </Grid>
            </Grid>
            <Paper style={{ borderRadius: '0px', overflow: 'scroll', height: `${window.innerHeight - 210}px` }}>
                <Table className='mui-table-format'>
                    <ConfigHd />
                    <TableBody>
                        {configList?.list?.length ? configList?.list.map(item => (
                            <ConfigListing
                                props={props}
                                setAddEditConfig={(data) => { setError({}); setAddEditConfig({ modal: true, data: data }) }}
                                item={item}
                                onDelete={() => setDeleteConfigModal({ modal: true, data: item })}
                            />
                        ))
                            : null
                        }
                    </TableBody>
                </Table>
                {isLoading || configList?.list?.length ? null :
                    <EmptyCollection title={'No records found'} />}
            </Paper>
            {
                configList.pagination ?
                    <Pagination
                        pagination={configList.pagination}
                        onChangeRowsPerPage={(e,) => getConfigList({ page: 1, per_page: e.target.value })}
                        onChangePage={(event, newPage) => getConfigList({ page: newPage + 1, per_page: configList.pagination.per_page })}
                    />
                    : null}
            {
                addEditConfig?.modal ?
                    <AddEditConfig
                        error={error}
                        setError={setError}
                        addEditConfig={addEditConfig}
                        props={props}
                        toggleModalFn={() => setAddEditConfig(false)}
                        saveConfig={saveConfigData}
                    /> : null
            }
            {deleteConfigModal.modal ?
                <DeletePopUp
                    modal={deleteConfigModal.modal}
                    toggleModalFn={() => setDeleteConfigModal({ modal: false, data: null })}
                    title="Delete Config Data"
                    content={<h4>Are you sure you want to delete?</h4>}
                    triggerEventFn={onDeleteConfig}
                /> : null
            }
            {
                isLoading ? <PageLoader /> : null
            }
        </Paper>
    )
}
export default withRouter(InitialConfig)