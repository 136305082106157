import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const CircularProgress = ({width='37px', height='37px', value}) => {
  return(
    <div style={{ width: width, height: height, display:'flex' }}>
      <CircularProgressbar
        text={value == 1? `${(value*100).toFixed(0)}%`:`${(value*100).toFixed(2)}%`}
        value={(value).toFixed(2)*100}
        styles={buildStyles({
          rotation: 1,
          strokeLinecap: 'butt',
          textSize: '24px',
          pathTransitionDuration: 0,
          textColor: '#a93232',
          trailColor: '#d6d6d6',
          backgroundColor: '#3e98c7',
        })}
      />
    </div>
  )
}
export default CircularProgress;
