import React, { useState, useEffect } from 'react';
import { Paper, Button, Dialog, Grid, TextField, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow, Tabs, Tab, FormControlLabel, Checkbox } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PageLoader } from '../../../shared_elements';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { revAssetTypeValues } from '../../../constants';
export default function TemplateAddEdit({ addTemplateModal, toggleModalFn, newFolderData, assetData, mainassetInfoData, submitWithTemplate, templateData, dropdownPayload }) {
    const [templates, setTemplates] = useState([])
    const [checkedFolders, setCheckedFolders] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [maxObj, setMaxObj] = useState(0);
    const [importCrud, setImportCrud] = useState({ template: null });
    const [templateCrud, setTemplateCrud] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        getTemplateList()
    }, [])
    useEffect(() => {
        if (templateCrud.length > 0) {
            setMaxObj(findMaxDepth(templateCrud));
        }
    }, [templateCrud]);

    const getTemplateList = (query) => {
        globalGetService(`records/v2/lessor-folder-template/`, {...dropdownPayload, dropdown: true })
            .then(response => {
                if (checkApiStatus(response)) {
                    let selectedTemplate = response.data.data?.list.find(item => item?.id == templateData?.templateDetails.id)
                    setImportCrud({ template: selectedTemplate })
                    onChangeTemplate(selectedTemplate, 'apicall')
                    setTemplates(response.data.data.list)
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            });
    }

    const onChangeTemplate = (template, apiCall) => {
        setLoading(true)
        setImportCrud({ template });
        let templatPayload = {};
        let maAssetType = templateData?.asset_type == 1 ? templateData?.majorAssemblyAsset ? revAssetTypeValues[templateData?.majorAssemblyAsset?.major_assembly_type.toLowerCase()]?.assetType : null : templateData?.asset_type?.value
        templatPayload = {
            ...templatPayload,
            asset_type: mainassetInfoData?.asset_type,
            asset: mainassetInfoData,
            asset_model_type: templateData?.majorAssemblyAsset,
            majorAssemblyAssetType: maAssetType,
            majorAssemblyAsset: {},
            // majorAssemblyAsset: templateData?.asset_type == 1 ? (templateData?.majorAssemblyAsset || {}) : templateData?.asset_type,
            serial_number: mainassetInfoData?.msn ? mainassetInfoData?.msn : mainassetInfoData?.esn ? mainassetInfoData?.esn : mainassetInfoData?.serial_number,
            position: null,
            is_major_assembly: mainassetInfoData?.asset_type ===1 && templateData?.asset_type?.value !== 1 ?true: false
        }
        globalPostService(`records/v2/get-lessor-folder-template/`, { ...templatPayload, template_id: template?.id })
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    let responseData = response.data?.data?.folderDetails || []
                    setTemplateCrud(responseData);
                    responseData?.map(item => onFieldChange(item, item?.is_checked, apiCall))
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            })
    }
    const onFieldChange = (folder, checked, apiState) => {
        let initialData = [...checkedFolders]
        if (apiState == 'dropdown') {
            initialData = []
        }
        const updatedCheckedFolders = initialData;
        const toggleCheck = (folder, isChecked) => {
            const folderIndex = updatedCheckedFolders.findIndex(item => item.folder_name === folder.folder_name && item?.parent_folder == folder?.parent_folder);
            const folderData = {
                is_checked: isChecked,
                level: folder?.level,
                parent_folder: folder?.parent_folder,
                naming_conventions: folder?.naming_conventions,
                folder_name: folder?.folder_name,
                childrens: folder?.childrens
            };

            if (folderIndex === -1) {
                updatedCheckedFolders.push(folderData);
            } else {
                updatedCheckedFolders[folderIndex] = folderData;
            }
            if(apiState == 'checkbox'){
                folder.childrens.forEach(child => toggleCheck(child, isChecked));
            }
            else{
                folder.childrens.forEach(child => toggleCheck(child, child?.is_checked));
            }
            // folder.childrens.forEach(child => toggleCheck(child, isChecked));
        };
        toggleCheck(folder, checked);
        if (apiState == 'apicall' || apiState == 'dropdown' || (checkedFolders?.length < 1 && addTemplateModal?.apiCall == 'apicall' && apiState != 'checkbox')) {
            setCheckedFolders(prevState => ([...prevState, ...updatedCheckedFolders]))
        }
        else {
            setCheckedFolders(updatedCheckedFolders);
        }
    };
    const onSubmitData = () => {
        let payload = {
            folderDetails: checkedFolders,
            templateDetails: importCrud.template,
            recordsNewFolderName: newFolderData,
            asset_type: mainassetInfoData?.asset_type,
            asset: { serial_number: mainassetInfoData?.msn ? mainassetInfoData?.msn : mainassetInfoData?.esn ? mainassetInfoData?.esn : mainassetInfoData?.serial_number, id: mainassetInfoData?.id }
        }
        submitWithTemplate(payload, importCrud)
    };
    const findMaxDepth = (folders) => {
        let maxDepth = 0;
        const traverse = (folders, depth) => {
            folders.forEach((folder) => {
                if (folder.childrens.length > 0) {
                    traverse(folder.childrens, depth + 1);
                } else {
                    maxDepth = Math.max(maxDepth, depth);
                }
            });
        };
        traverse(folders, 1);
        return maxDepth;
    };

    const renderRows = (folders, depth = 1, parentIndex = '') => {
        const maxDepth = findMaxDepth(templateCrud);
        return folders.flatMap((folder, index) => {
            const rows = [];
            const uniqueKey = `${parentIndex}-${index}-${folder.folder_name}`; // Generate unique key
            const indent = Array.from({ length: depth - 1 }).map(() => (
                <TableCell key={Math.random()} />
            ));
            rows.push(
                <TableRow key={uniqueKey}>
                    {indent}
                    <TableCell>
                        <FormControlLabel control={<Checkbox size="small" color='primary' checked={checkedFolders.find(item => (item.folder_name === folder.folder_name) && (item.parent_folder?.length ? item.parent_folder[0] === folder.parent_folder[0] : true))?.is_checked || false} onChange={(e) => onFieldChange(folder, e.target.checked, 'checkbox')} name="sub_folder" />} label={<p style={{ fontSize: '13px' }}>{folder.folder_name}</p>} />
                    </TableCell>
                    {Array.from({ length: maxDepth - depth }).map((_, i) => (
                        <TableCell key={`empty-${uniqueKey}-${i}`} />
                    ))}
                    <TableCell>{folder.naming_conventions}</TableCell>
                </TableRow>
            );
            if (folder.childrens.length > 0) {
                rows.push(...renderRows(folder.childrens, depth + 1, uniqueKey));
            }
            return rows;
        });
    };
    const changeTemplateFn = (value) => {
        setImportCrud({})
        setCheckedFolders([])
        if (value?.id) {
            setLoading(true)
            setTimeout(() => { onChangeTemplate(value, 'dropdown') }, 400);
        }
        else { 
            
         }
    }
    return (
        <>
            {isLoading ? <PageLoader /> : null}
            <Dialog
                open={addTemplateModal?.flag}
                onClose={() => { toggleModalFn(false) }}
                className='console-management-modal'
                maxWidth={false}
            >
                <DialogTitle id="scroll-dialog-title">
                    Select Template Folders
                </DialogTitle>
                <DialogContent dividers={true} >
                    <div style={{ minHeight: '600px', minWidth: '500px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    style={{ marginBottom: '20px' }}
                                    options={templates}
                                    getOptionLabel={option => option.name}
                                    id="template"
                                    value={importCrud.template || null}
                                    onChange={(e, value) => changeTemplateFn(value)}
                                    renderInput={params => <TextField required {...params} label="Select Template" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            {templateCrud?.length ?
                                <div style={{ overflow: "scroll", width: `${(40) + (maxObj * 20)}%`, minWidth: "100%" }}>
                                    <Table className='mui-table-format'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell >Main Folders</TableCell>
                                                {
                                                    Array.from({ length: maxObj - 1 }).map((item, index) => (
                                                        <TableCell >Sub Folder {index + 1} </TableCell>
                                                    ))
                                                }
                                                <TableCell width='200px'>Naming Conventions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {templateCrud?.length ?
                                                renderRows(templateCrud)
                                                : null
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                                :
                                <Paper style={{ textAlign: 'center', padding: '20px' }}>
                                    {isLoading ?
                                        <CircularProgress size={24} /> :
                                        'Choose template to you wish to select.'
                                    }
                                </Paper>
                            }
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={(checkedFolders.filter(item => item?.is_checked == true)?.length == 0) || isLoading} onClick={onSubmitData} color='primary' size='small' variant='contained'>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
