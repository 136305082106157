import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';
import { Link } from 'react-router-dom';
import { Button, Breadcrumbs, Typography } from '@material-ui/core';
import { STableLoader } from '../../../shared_elements/loaders';
import { TableListComp, PageLoader, EmptyCollection, DeletePopUp } from '../../../shared_elements';
import { DataKitList, KitsBulkOps, ShareContentModal } from '../components';
import { checkPermission, getLocalStorageInfo } from '../../../utils';
import { getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import * as actions from '../../../shared_elements/actions'
import { imageMedia, videoTypes } from '../../../constants'
import { checkApiStatus } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { getResourcesKitFilesApi, removeDataKitFilesApi, downloadResourceKitApi, shareDataRoomDataApi, getKitsDownloadUrlsApi,getRecordsDetailApiV_2 } from '../apiServices';
import { dmKitsHd, toggleBulkOpsFn, applyFilterFn, onChangeBulkOps, listSingleActionFn } from '../'
class DataKits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: false,
      pageLoader: false,
      bulkOperation: { ids: [], modal: false },
      filter: {},
      applyingFilter: {},
      dataKits: {
        list: [],
        pagination: null
      },
      shareContent: {
        data: {},
        modal: false
      },
      urlList: []
    }
    this.getResourcesKitFilesApi = getResourcesKitFilesApi.bind(this);
    this.removeDataKitFilesApi = removeDataKitFilesApi.bind(this);
    this.downloadResourceKitApi = downloadResourceKitApi.bind(this);
    this.shareDataRoomDataApi = shareDataRoomDataApi.bind(this);
    this.getKitsDownloadUrlsApi = getKitsDownloadUrlsApi.bind(this);

    this.toggleBulkOpsFn = toggleBulkOpsFn.bind(this);
    this.applyFilterFn = applyFilterFn.bind(this);
    this.onChangeBulkOps = onChangeBulkOps.bind(this);
    this.listSingleActionFn = listSingleActionFn.bind(this);
    this.getRecordsDetailApiV_2 = getRecordsDetailApiV_2.bind(this);
  }
  componentDidMount() {
    this.getResourcesKitFilesApi(this.props, { tab: this.props.match.params.kitType }, 'skeletonLoader');
    if (!this.props.pltConstants.filter(item => item.type === 'file_extension_drive').length) {
      this.props.getPlatformConstants();
      trackActivity('Page Visited', {
        page_title: `${this.props.match.params.kitType === 'marketing_kit' ? 'Marketing ' : 'Engineering'} Data Kit`
      })
    }
    this.getRecordsDetailApiV_2(this.props);
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter, dataKits } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getResourcesKitFilesApi(this.props, { ...query, tab: this.props.match.params.type, per_page: dataKits.pagination.per_page }, 'pageLoader');
    this.setState({ applyingFilter: delete applyingFilter[keyParam] })
  }
  toggleShareContent = () => {
    const { dataKits } = this.state;
    let shareData = { ...dataKits.shared_details, type: 'file', name: this.props.match.params.kitType === 'marketing_kit' ? 'Marketing Data Kit' : 'Engineering Data Kit' }
    this.setState(prevState => ({
      ...prevState,
      shareContent: {
        ...prevState.shareContent,
        modal: true,
        data: shareData
      }
    }));
  }
  render() {
    const { skeletonLoader, pageLoader, shareContent, bulkOperation, filter, dataKits,fileInfo } = this.state;
    const { pltConstants, recordsRepos, match } = this.props;;
    return (
      <section className="action-docks">
        <div className="heading flex-centered">
          {recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.technical_records ?
            <Breadcrumbs aria-label="breadcrumb">
              <Link to={`/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/${recordsRepos.record_folders.technical_records.uuid}`}>Technical</Link>
              <Typography>
                {this.props.match.params.kitType === 'marketing_kit' ? 'Marketing ' : 'Engineering '} Data Kit
              </Typography>
            </Breadcrumbs> : null
          }

        </div>
        <KitsBulkOps
          dataKits={dataKits}
          recordsRepos={recordsRepos}
          bulkOperation={bulkOperation}
          onChangeBulkOps={this.onChangeBulkOps}
          downloadResourceKit={() => this.getKitsDownloadUrlsApi(this.props, { download: this.props.match.params.kitType })}
          toggleShareContent={this.toggleShareContent}
        /> 
        <Fragment>
          {skeletonLoader ?
            (<STableLoader count={5} />) :
            <Fragment>
              <TableListComp
                bulkOperation={checkPermission('records','category','D')}
                bulkFlag={dataKits.list.length === bulkOperation.ids.length && dataKits.list.length > 0}
                toggleBulkOps={(flag) => this.toggleBulkOpsFn(flag, 'all')}
                heads={dmKitsHd}
                data={dataKits.list.map((item, index) =>
                  <DataKitList
                    item={item}
                    index={index}
                    fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                    bulkOperation={bulkOperation}
                    toggleBulkOps={(flag) => this.toggleBulkOpsFn(flag, 'single', item.uuid)}
                    listSingleActionFn={(title) => this.listSingleActionFn(title, item.uuid)}
                  />
                )}
                pagination={dataKits.pagination}
                onChangePage={(event, newPage) => { this.setState({ bulkOperation: { ids: [], modal: false } }); this.getResourcesKitFilesApi(this.props, { tab: this.props.match.params.kitType, page: newPage + 1, per_page: dataKits.pagination.per_page, ...filter }, 'pageLoader') }}
                onChangeRowsPerPage={(event) => this.getResourcesKitFilesApi(this.props, { tab: this.props.match.params.kitType, page: 1, per_page: event.target.value }, 'pageLoader')}
                noRecord={dataKits.list.length ? null :
                  <EmptyCollection
                    title="No Records Found"
                  />
                }
              />
            </Fragment>
          }
        </Fragment>
        {bulkOperation.modal ?
          <DeletePopUp
            modal={bulkOperation.modal}
            confirmText="Remove"
            toggleModalFn={() =>
              this.setState(prevState => ({
                ...prevState,
                bulkOperation: {
                  ...prevState.bulkOperation,
                  modal: false,
                  ids: prevState.bulkOperation.multi ? prevState.bulkOperation.ids : []
                }
              }))
            }
            title={bulkOperation.title ? bulkOperation.title : ''}
            content={<h4>{`Are you sure you want to Remove?`}</h4>}
            triggerEventFn={() => this.removeDataKitFilesApi(this.props, bulkOperation, 'remove')}
          /> : null
        }
        {shareContent.modal ?
          <ShareContentModal
            asset={fileInfo}
            shareContent={shareContent}
            toggleModalFn={() => this.setState({ shareContent: { modal: false, data: {} } })}
            sharetheDmContent={(flag, hour) => this.shareDataRoomDataApi(this.props, { type: 'folder', uuid: this.props.match.params.uuid, share: flag, hour: hour }, this.props.match.params.kitType)}
          /> : null
        }

        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  recordsRepos: state.shareReducer.recordsRepos,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ['file_extension_drive'] })),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(DataKits))
