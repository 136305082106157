import React, { useState } from 'react'

export default function ReadMoreInline({ text, maxLength = '50', defaultText = '--', readMoreText = 'more', lessText = 'less' }) {
    const [showFullText, setShowFull] = useState(false)
    return (
        <span className='read-more-less-blk' >
            {
                text?.length > maxLength ?
                    <div>
                        {
                            showFullText ?
                                <div>
                                    {text}
                                    <span className='read-more-txt' onClick={(e) => {setShowFull(!showFullText); e.stopPropagation()}} > {lessText}</span>
                                </div>
                                :
                                <div>
                                    {text?.substring(0, (maxLength - 3))}
                                    <span className='read-more-txt' onClick={(e) => {setShowFull(!showFullText) ;e.stopPropagation()}}> ...{readMoreText}</span>
                                </div>
                        }
                    </div>
                    :
                    <span>{text || defaultText}</span>
            }
        </span>
    )
}
