import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, TableRow, TableCell, Avatar } from '@material-ui/core';
import { assetTypeValues } from '../../../constants';
import { getLocalStorageInfo, localTimeFn } from '../../../utils';
const Activity = ({item , history}) => {
const plotActivity = (item) => {
  switch (item.activity_type.value) {
    case 1:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user && item.user.name ? item.user.name : '--'} uploaded `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`: item.other_info.details[0] ? item.other_info.details[0]?.name : '--'} to <b className="last-bold">{item.other_info && item.other_info.parent_folder && item.other_info.parent_folder.name ? item.other_info.parent_folder.name : '--'}</b> folder
        </div>
      )
    case 2:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} renamed the ${item.other_info.type !== 'file' ? 'folder' : 'file'} from `}
          <b className="last-bold"><s>{item.other_info.old_name != null ?item.other_info.old_name:'--'}</s></b> to <b className="last-bold">{item.other_info.new_name}</b>
        </div>
      )
    case 3:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} published `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0]?.name}
        </div>
      )
    case 4:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} rejected `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0]?.name}
        </div>
      )
    case 5:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} restored `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0]?.name} from Rejected Queue
        </div>
      )
    case 6:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} moved `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0] ? item.other_info.details[0]?.name : ''} {item.other_info.move_to_other_asset? `(${item.other_info.from_folder.asset.serial_number})`: null} to <b>{item.other_info && item.other_info.to_folder && item.other_info.to_folder.name ? item.other_info.to_folder.name : ''} {item.other_info.move_to_other_asset? `(${item.other_info.to_folder?.asset.serial_number})` : null}</b>
        </div>
      )
    case 7:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} moved `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} folders`:item.other_info.details[0]?.name} {item.other_info?.move_to_other_asset? `(${item.other_info.from_folder?.asset.serial_number})`: null} to <b>{item.other_info && item.other_info.to_folder && item.other_info.to_folder.name ? item.other_info.to_folder.name : ''} {item.other_info.move_to_other_asset? `(${item.other_info.to_folder?.asset.serial_number})` : null}</b>
        </div>
      )
    case 8:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} deleted `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0]?.name}
        </div>
      )
    case 9:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} deleted `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} folders`:item.other_info.details[0]?.name}
        </div>
      )
    case 10:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} restored the file `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0]?.name} from Recycle Bin
        </div>
      )
    case 11:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} restored the folder `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} folders`:item.other_info.details[0]?.name} from Recycle Bin
        </div>
      )
    case 12:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} permanently deleted `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} file`:item.other_info.details[0]?.name}
        </div>
      )
    case 13:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} permanently deleted `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} folders`:item.other_info.details[0]?.name}
        </div>
      )
    case 14:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} enabled share link for file `}
          <b>{item.other_info.details[0]?.name}</b>
          {item.other_info.validity != null && item.other_info.validity != undefined && parseInt(item.other_info.validity) != 0 ? (item.other_info.validity/24) > 1 ? ` for ${Math.ceil(item.other_info.validity/24)} Days` : ` for ${Math.ceil(item.other_info.validity/24)} Day` : ''}
        </div>
      )
    case 15:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} enabled share link for folder `}
          <b>{item.other_info.details[0]?.name}</b>
          {item.other_info.validity != null && item.other_info.validity != undefined && parseInt(item.other_info.validity) != 0 ? (item.other_info.validity/24) > 1 ? ` for ${Math.ceil(item.other_info.validity/24)} Days` : ` for ${Math.ceil(item.other_info.validity/24)} Day` : ''}
        </div>
      )
    case 16:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} disabled share link for file `}
          <b>{item.other_info.details[0]?.name}</b>
        </div>
      )
    case 17:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} disabled share link for folder `}
          <b>{item.other_info.details[0]?.name}</b>
        </div>
      )
    case 18:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} `} <b>{item.description}</b>
        </div>
      )
    case 19:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} updated share link duration for folder `}
          <b>{item.other_info.details[0]?.name}</b>
          {item.other_info.validity != null && item.other_info.validity != undefined && parseInt(item.other_info.validity) != 0 ? item.other_info.validity > 1 ? ` to ${Math.ceil(item.other_info.validity)} Days` : ` for ${Math.ceil(item.other_info.validity)} Day` : ''}
        </div>
      )
    case 20:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} unpublished `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0]?.name}
        </div>
      )
    case 21:
      return(
        <>
        {
          item.other_info.to_folder ?
          <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} collaborated `}
          <b>{item.other_info.details[0]?.name}</b> with <b>{item.other_info.collaborated_with.map(item => item.name).join(',')}</b>
          </div> :
          <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} `}
            {item.description}
          </div>
        }
        </>
      )
    case 22:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} created folder `}
          <b>{item.other_info.details[0]?.name}</b>
        </div>
      )
    case 23:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} downloaded `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0]?.name}
        </div>
      )
    case 24:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} downloaded `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} folders`:item.other_info.details[0]?.name}
        </div>
      )
    case 25:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} added `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0]?.name} to <b>{item.other_info.category_type}</b>
        </div>
      )
    case 26:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} exported `}
          <b>{item.other_info.details}</b>
        </div>
      )
    case 27:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name}`}
          <b> {item.description}</b>
        </div>
      )
    case 28:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} Removed the file `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0]?.name} from <b>{item.other_info.category_type}</b>
        </div>
      )
    case 29:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} Visited `}
          <b>{item.other_info.details[0]?.name}</b>
        </div>
      )
    case 30:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} Visited `}
          <b>{item.other_info.details[0]?.name}</b>
        </div>
      )
    case 31:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} Previewed `}
          <b>{item.other_info.details[0]?.name}</b>
        </div>
      )
      case 32:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} viewed folder  `}
          <b>{item.other_info.details[0]?.name}</b>
        </div>
      )
    case 33:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} Downloaded `}
          <b>{item.other_info.details[0]?.name}</b>
        </div>
      )
    case 34:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} Downloaded `}
          <b>{item.other_info.details[0]?.name}</b>
        </div>
      )
    case 35:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} `}
          <b>{item.description}</b>
        </div>
      )
    case 36:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} `}
          <b>{item.description}</b>
        </div>
      )
    case 37:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} `}
          <b>{item.description}</b>
        </div>
      )
    case 38:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} `}
          <b>{item.description}</b>
        </div>
      )
    case 39:
      return (
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} `}
          <b>{item.description}</b>
        </div>
      )
      case 40:
        return(
          <div>
            {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name}  `}
            <b>{item.description}</b>
          </div>
        )
     case 41:
        return(
          <div>
            {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name}  `}
            <b>{item.description}</b>
          </div>
        )
     case 42:
        return(
          <div>
            {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name}  `}
            <b>{item.description}</b>
          </div>
        )
      case 43:
        return(
          <div>
            {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} shared `}
            <b>{item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} folders`:item.other_info.details[0]?.name}</b>
            <b>{item.description}</b>
          </div>
        )
      case 44:
        return(
          <div>
            {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} shared `}
            <b>{item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0]?.name}</b>
            <b>{item.description}</b>
          </div>
        )
        case 45:
        return(
          <div>
            {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} `}
            <b>{item.description}</b>
          </div>
        )
      case 46:
        return(
          <div>
            {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name}  `}
            <b>{item.description}</b>
          </div>
        )
        case 47:
        return(
          <div>
            {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name}  `}
            <b>{item.description}</b>
          </div>
        )
        case 48:
        return(
          <div>
            {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name}  `}
            <b>{item.description}</b>
          </div>
        )
        case 49:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} copied `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} files`:item.other_info.details[0] ? item.other_info.details[0]?.name : ''} {item.other_info.move_to_other_asset? `(${item.other_info.from_folder.asset.serial_number})`: null} to <b>{item.other_info && item.other_info.to_folder && item.other_info.to_folder.name ? item.other_info.to_folder.name : ''} {item.other_info.move_to_other_asset? `(${item.other_info.to_folder?.asset.serial_number})` : null}</b>
        </div>
      )
      case 50:
      return(
        <div>
          {`${getLocalStorageInfo().user.id === item.user.id ? 'You':item.user.name} copied `}
          {item.other_info.count && item.other_info.count > 1 ? `${item.other_info.count} folders`:item.other_info.details[0]?.name} {item.other_info?.move_to_other_asset? `(${item.other_info.from_folder?.asset.serial_number})`: null} to <b>{item.other_info && item.other_info.to_folder && item.other_info.to_folder.name ? item.other_info.to_folder.name : ''} {item.other_info.move_to_other_asset? `(${item.other_info.to_folder?.asset.serial_number})` : null}</b>
        </div>
      )
    default:
      return(<div>{'--'}</div>)
    }
  }
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <div style={{width:"420px"}}>{plotActivity(item)}</div>
      </TableCell>
      <TableCell>
        <div style={{width:'120px'}} className="asset-info">
          {item.asset && item.asset.type ? <p>{item.asset.type}</p> :null }
          <h5>
            {item.asset && item.asset.serial_number ? item.asset.serial_number:''}
          </h5>
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'120px'}}>
          {item.user.name}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'120px'}}>
          {localTimeFn(item.activity_time)}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'120px'}}>
          {item.device_info && item.device_info.ip ? item.device_info.ip :'--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'120px'}}>
          {item.device_info && item.device_info.device_type ? item.device_info.device_type :'--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'120px'}}>
          {item.device_info && item.device_info.browser_type ? item.device_info.browser_type :'--'}<br/>
          { item.device_info && item.device_info.browser_version ? <p style={{fontSize:'11px'}}>{'Version: '+item.device_info.browser_version}</p> :''}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'120px'}}>
          {item.device_info && item.device_info.os_type ? item.device_info.os_type :'--'}<br/>
          { item.device_info && item.device_info.os_version ? <p style={{fontSize:'11px'}}>{'Version: '+item.device_info.os_version}</p> :''}
        </div>
      </TableCell>
    </TableRow>
  )
}
export default withRouter(Activity);
