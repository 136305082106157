import React from 'react';
import { Button, Grid } from '@material-ui/core';
import componentPermissionHoc from '../../../hoc/componentPermissionHoc'
import { checkPermission } from '../../../utils';
const EmptyFolder = ({ toggleUploadModalFn, showUpload, applyTemplate }) => {
  return (
    <div className="empty-folder">
      <div style={{ height: 'inherit' }}>
        {checkPermission('records', 'data_room', 'UP') && showUpload ?
          <Grid container spacing='1' style={{ height: 'inherit' }}>
            <Grid item sm='6' style={{ borderRight: '1px dashed #000000', margin: '50px 0px 35px 0px' }}>
              <div style={{ position: 'relative', top: '39.4%' }}>
              <p style={{ marginBottom: '4px' }}>No Records in this Folder.</p>
                <Button onClick={toggleUploadModalFn} size="small" variant="contained" color="primary">Upload Now</Button>
              </div>
              <div style={{position: 'relative', left:'97.7%',top:'30%', backgroundColor:'#d9daed', width:'max-content', padding:"5px"}}>
                OR
              </div>
            </Grid>
            <Grid item sm='6'>
              <div style={{ position: 'relative', top: '44%' }}>
                <p style={{ marginBottom: '4px' }} >Click on Apply Template if you wish to apply a template.</p>
                <Button onClick={() => { applyTemplate() }} size="small" variant="contained" color="primary">Apply Template</Button>
              </div>
            </Grid>
          </Grid>
          :
          <div className="upload-now-content"> <p>This folder is empty.</p>  </div>
        }
      </div>
    </div>
  )
}
export default EmptyFolder;
