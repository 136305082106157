import React, { Fragment ,useRef }  from 'react';
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import config from '../../../config';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, FormControlLabel, Switch, Radio, RadioGroup } from '@material-ui/core';
import { AutocompleteCreatable } from '../../../shared_elements';
import FolderCollabrationModal from './FolderCollabrationModal';
import PublicIcon from '@material-ui/icons/Public';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { localTimeFn,getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const ShareContentModal = ({ shareContent, getCollabResponseBack, toggleModalFn, sharetheDmContent, navigation, isRootNavigation,asset }) => {
  const [customDur, setCustomDur] = React.useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateDuration, setUpdateDuration] = React.useState(false);
  const [defaultDur, setDefaultDur] = React.useState(shareContent.data.valid_till && [1, 3, 7, 10].includes(moment(shareContent.data.valid_till).endOf('day').diff(moment().endOf('day'), 'days')) ? moment(shareContent.data.valid_till).endOf('day').diff(moment().endOf('day'), 'days') * 24 :getLocalStorageInfo()?.defaultLessor?.id === 242 ? 2160 : 24);
  const onFieldChange = (duration) => {
    setCustomDur(duration);
  }
  const textRef = useRef(null);
  const copyContent = () => {
    if (textRef.current) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(textRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
      enqueueSnackbar('Generated Link copied successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  };
  const assetTypesMapping = {
    1: { label: 'MSN', key: 'msn' },
    2: { label: 'APU', key: 'esn' },
    3: { label: 'APU', key: 'serial_number' },
    4: { label: 'LG', key: 'serial_number' },
    5: { label: 'Propeller', key: 'serial_number' },
    2: { label: 'ESN', key: 'esn' },
    6: { label: 'FAN', key: 'esn' },
    7: { label: 'HPC', key: 'esn' },
    8: { label: 'HPT', key: 'esn' },
    9: { label: 'LPT', key: 'esn' }
  };
  
  const getAssetTypeLabelAndInfo = (asset) => {
    const assetType = asset?.asset_type;
    const mapping = assetTypesMapping[assetType];
    
    if (mapping) {
      const label = mapping.label;
      const info = asset[mapping.key];
      return `${label} ${info}`;
    }
    
    return asset.msn;
  };
  
  const assetOption = { asset_type: asset?.asset_type, serial_number:'SN' + " " + asset?.serial_number + ' ' + "SPARTA Records Link"  ,esn:'SN' + " " + asset?.esn + ' ' +  'SPARTA Records Link' ,msn:'SN' + " " + asset?.msn + ' ' + "SPARTA Records Link"};
  const value = getAssetTypeLabelAndInfo(assetOption);
  return (
    <Dialog
      open={shareContent.modal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth='true'
    >
      <DialogTitle id="scroll-dialog-title">
        Share
        {isRootNavigation ?
          navigation && navigation.length === 1 ?
            ` "${shareContent.data.name}"`
            : " " + navigation[navigation.length - 1].name : " " + shareContent.data.name
        }
        <span style={{ float: 'right', cursor: 'pointer' }} onClick={() => toggleModalFn(false)}><CloseIcon /></span>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="data-share-modal">
              <div className="public-link-card">
                <h4>Share Link</h4>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shareContent.data.share_enabled ? true : false}
                        onChange={(e) => sharetheDmContent(e.target.checked, 24)}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={shareContent.data.share_enabled ? 'Shared link is created' : 'Create Share Link'}
                  />
                  {shareContent.data.share_enabled ?
                    <Fragment>
                      <div>
                        <form style={{ position: 'relative' }}>
                          <TextField
                            id="copyShareLink"
                            fullWidth
                            margin="none"
                            variant="outlined"
                            size="small"
                            value={value}
                            InputLabelProps={{ shrink: true }}
                          />
                          <span ref={textRef} style={{ position: 'fixed', top: '-9999px' }}>{`${config.api.appUrl}records/shared-data/${shareContent.data.share_key}`}</span>
                          <Button style={{ position: 'absolute', bottom: 0, right: 0, top: 0, padding: '0 20px', border: '1px solid #d7d7d7', borderRadius: '0px', background: '#e2e2e2' }} onClick={copyContent} >Copy</Button>
                        </form>
                        <div className="share-advance-setting">
                          {
                            shareContent.data.valid_till || shareContent.data.share_duration ?
                              <h6 style={{ marginBottom: '10px' }}>
                                <span>Valid Till: </span>
                                {
                                  shareContent.data.valid_till ? localTimeFn(shareContent.data.valid_till) : isRootNavigation && shareContent.data.share_duration ? moment(shareContent.data.share_duration).format('MMM DD, YYYY HH:MM A ') : moment()
                                }

                                <Button style={{ fontSize: '11px', marginLeft: '10px', padding: '2px 10px' }} variant="contained" color="primary" onClick={() => setUpdateDuration(true)}>Change</Button></h6> : null}
                          {
                            updateDuration ?
                              <Fragment>
                                <p>Change the link Validity:</p>
                                <RadioGroup aria-label="duration" name="duration" >
                                  <ul className="list-inline flex-centered">
                                    <li className="list-inline-item">
                                      <FormControlLabel value="24" control={<Radio checked={customDur === '' && defaultDur === 24} onChange={() => { setDefaultDur(24); sharetheDmContent(true, 24); setCustomDur('') }} size="small" color="primary" />} label="1 Day" />
                                    </li>
                                    <li className="list-inline-item">
                                      <FormControlLabel value="72" control={<Radio checked={customDur === '' && defaultDur === 72} onChange={() => { setDefaultDur(72); sharetheDmContent(true, 72); setCustomDur('') }} size="small" color="primary" />} label="3 Day" />
                                    </li>
                                    <li className="list-inline-item">
                                      <FormControlLabel value="168" control={<Radio checked={customDur === '' && defaultDur === 168} onChange={() => { setDefaultDur(168); sharetheDmContent(true, 168); setCustomDur('') }} size="small" color="primary" />} label="7 Day" />
                                    </li>
                                    <li className="list-inline-item">
                                      <FormControlLabel value="240" control={<Radio checked={customDur === '' && defaultDur === 240} onChange={() => { setDefaultDur(240); sharetheDmContent(true, 240); (setCustomDur('')) }} size="small" color="primary" />} label="10 Day" />
                                    </li>
                                   { getLocalStorageInfo()?.defaultLessor?.id === 242 &&<li className="list-inline-item">
                                      <FormControlLabel value="2160" control={<Radio checked={customDur === '' && defaultDur === 2160} onChange={() => { setDefaultDur(2160); sharetheDmContent(true, 2160); (setCustomDur('')) }} size="small" color="primary" />} label="90 Day" />
                                    </li>}
                                    <li>
                                      <p>Custom Days:</p>
                                      <TextField
                                        fullWidth
                                        margin="none"
                                        variant="outlined"
                                        size="small"
                                        value={customDur}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e.target.value) : e.preventDefault() }}
                                        onBlur={(e) => e.target.value.trim().length ? sharetheDmContent(true, parseInt(e.target.value) * 24) : e.preventDefault()}
                                        style={{ height: '26px' }}
                                      />
                                    </li>
                                  </ul>
                                </RadioGroup>
                              </Fragment>
                              : null
                          }
                        </div>

                        <p className="flex-centered"><PublicIcon color="primary" /> This content is publicly available to anyone with the link(having view and download access).</p>
                      </div>
                    </Fragment> : null
                  }
                </div>
              </div>
              {shareContent.data.type === 'folder' ?
                <div className="invite-collabration">
                  <FolderCollabrationModal getCollabResponseBack={getCollabResponseBack} isRootNavigation={isRootNavigation} toggleModal={() => toggleModalFn()} item={shareContent.data} navigation={navigation} />
                </div> : null
              }
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default withSnackbar(withRouter(ShareContentModal));
