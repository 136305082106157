import Templates from './containers/Templates';
import CheckListTemplate from './containers/CheckListTemplate';
import Tags from './containers/Tags';
import PageLayout from '../../hoc/PageLayout';
import UserRoleAccess from '../../hoc/UserRoleAccess';
import ConfigMiniPack from './containers/ConfigMiniPack';
import MiniPackSections from './components/MiniPackSections';
export const settingsRoutes = [
  {
    path: '/settings/templates',
    component: PageLayout(Templates, {apps: 'Settings', layoutPhase: 2}),
    key: 'Templates',
    apps:'records'
  },
  {
    path: '/settings/mini-pack',
    component: PageLayout(ConfigMiniPack , {apps: 'ConfigMiniPack', layoutPhase: 2}),
    key: 'ConfigMiniPack',
    apps:'records'
  },
  {
    path: '/settings/mini-pack-section/:slug',
    component: PageLayout(MiniPackSections , {apps: 'MiniPackSections', layoutPhase: 2}),
    key: 'MiniPackSections',
    apps:'records'
  },
  {
    path: '/settings/tags',
    component: PageLayout(UserRoleAccess(Tags, ['records', 'tags', 'R']), {apps: 'Tags', layoutPhase: 2}),
    key: 'Tags',
    apps:'records'
  },
  {
    path: '/templates',
    component: PageLayout(UserRoleAccess(CheckListTemplate, ['templates', 'templates', 'R']), {apps: 'Template', layoutPhase: 1}),
    key: 'Templates',
    apps:'records'
  },
]
