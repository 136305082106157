import React from 'react';
import moment from 'moment';
import {Drawer} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { displayDateFormatShort, imgPath } from '../../../constants';
import { getFileSize, getFileIcon } from '../../../utils';
import folderIcon from '../../../assets/images/folder_icon.svg'
const Properties = ({width='380px', dataProperties, onCloseDrawer, fileIcons}) => {
  return(
    <Drawer anchor="right" open={dataProperties.open} onClose={onCloseDrawer}>
      <div style={{width: width, position:'relative'}}>
        <CloseIcon onClick={onCloseDrawer} style={{position:'absolute', top:'5px', right:'5px', cursor:'pointer'}} />
        { dataProperties.open ?
          <div className="data-room-properties">
            <div className="folder-properties">
              <div className="text-center">
                <img
                  className="folder-img"
                  src={dataProperties.data.type === 'folder' ? folderIcon: getFileIcon(fileIcons, dataProperties.data.extension)} alt={dataProperties.data.name}
                />
                <h4>{dataProperties.data.name}</h4>
              </div>
              <div>
                <table>
                  <tr>
                    <td>Created At:</td>
                    <td>{dataProperties.data.created_at ? moment(dataProperties.data.created_at).format(displayDateFormatShort):'--'}</td>
                  </tr>
                  <tr>
                    <td>Created By:</td>
                    <td>{dataProperties.data.created_by ? dataProperties.data.created_by:'--'}</td>
                  </tr>
                  <tr>
                    <td>Modified At:</td>
                    <td>{dataProperties.data.modified_at ? moment(dataProperties.data.modified_at).format(displayDateFormatShort):'--'}</td>
                  </tr>
                  <tr>
                    <td>Modified By:</td>
                    <td>{dataProperties.data.modified_by ? dataProperties.data.modified_by:'--'}</td>
                  </tr>
                  { dataProperties.data.type === 'folder' ?
                    <tr>
                      <td>Files:</td>
                      <td>{dataProperties.data.file_count ? dataProperties.data.file_count:''}</td>
                    </tr>:null
                  }
                  <tr>
                    <td>Size:</td>
                    <td>{dataProperties.data.size ? getFileSize(dataProperties.data.size):'--'}</td>
                  </tr>
                  <tr>
                    <td>Page Count:</td>
                    <td>{dataProperties.data.type === 'folder' ? 'N/A' :  dataProperties.data.page_count ? dataProperties.data.page_count :'--'}</td>
                    {/* <td>{dataProperties.data.page_count ? dataProperties.data.page_count :'--'}</td> */}
                  </tr>
                </table>
              </div>
            </div>
          </div>:null
        }
      </div>
    </Drawer>
  )
}
export default Properties;
