import React, { useEffect, useState } from 'react'
import { ArrowBack } from '@material-ui/icons'
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import { AutocompleteCreatable, PageLoader } from '../../../shared_elements'
import { useSnackbar } from 'notistack';
import SectionFolderSelection from '../../settings/components/SectionFolderSelection';
import { Autocomplete } from '@material-ui/lab';
import { globalGetService } from '../../../utils/globalApiServices';
import { checkApiStatus, checkPermission } from '../../../utils';
import { revAssetTypeValues } from '../../../constants';

export default function AddEditMiniPackSections({ props, addEditSection, keywordsCreated, setKeywordsCreated, toggleModal, sectionData, setSectionData, saveSectionData, error, setError }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [addKeywords, setAddKeywords] = useState(false)
    const [maTypes, setMAType] = useState([])
    const [assemblyLoader, setAssemblyLoader] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const maAssets=[{ label: 'Engine', value: 2 }, { label: 'APU', value: 3 }, { label: 'Landing Gear', value: 4 }, { label: 'Propeller', value: 5 }]
    useEffect(()=>{
        if(sectionData?.id && sectionData?.major_assembly_asset_type?.value){
            getMajorAssembly(sectionData?.major_assembly_asset_type?.value, 'pageLoader')
        }
    },[])
    const getMajorAssembly = (assetType, loaderType) => {
        setMAType([])
        setAssemblyLoader(true)
        if(loaderType == 'pageLoader'){
            setLoading(true)
        }
        globalGetService(`console/v2/aircraft/${props?.match?.params?.assetSlug}/major-assemblies/`, { asset_type: assetType })
        .then(response => {
            setAssemblyLoader(false)
            setLoading(false)
            if (checkApiStatus(response)) {
                    setMAType(response.data.data?.list)
                }
            })
    }
    const onFieldChange = (key, value, type) => {
        if (key == 'keywords') {
            let checkArr = [...value]
            let newVal = checkArr.pop()
            let prevVal = checkArr
            if (sectionData.keywords?.length <= value?.length && prevVal?.length && prevVal?.filter(item => item?.name.trim() == newVal?.inputValue.trim())?.length) {
                enqueueSnackbar('This folder name is already selected.', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
            else {
                setKeywordsCreated(value.map(item => ({ ...item, name: item?.inputValue })))
                setSectionData({ ...sectionData, [key]: value.map(item => ({ ...item, name: item?.inputValue })) })
            }
        }
        else {
            if (key == 'major_assembly_asset_type') {
                getMajorAssembly(value?.value)
                setSectionData({ ...sectionData, major_assembly_asset: null, [key]: value })
            }
            else {
                setSectionData({ ...sectionData, [key]: value })
            }
        }
    }
    const onSubmitKeyWdData = (data, payload) => {
        let tempFolderData = data?.folderDetails.filter(item => item?.is_checked == true).map(item => { return ({ ...item, inputValue: item?.name }) })
        setSectionData(prevData => ({
            ...prevData, keywords: [...tempFolderData, ...keywordsCreated]
        }))
        setKeywordsCreated([...keywordsCreated, ...tempFolderData])
        setAddKeywords(false)
        // setTemplateData(data)
    }
    return (
        <>
        {
            isLoading? <PageLoader /> : null
        }
            <Dialog
                open={addEditSection?.modal}
                onClose={() => { toggleModal() }}
                aria-labelledby="scroll-dialog-title"
                className='records-dataroom-modal minipack-section-modal'
            >
                <DialogTitle> {addEditSection?.data ? 'Edit' : 'Add'} Section </DialogTitle>
                <DialogContent dividers={true} style={{ width: "500px" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="name"
                                fullWidth
                                margin="normal"
                                variant='outlined'
                                inputProps={{ maxLength: 50 }}
                                value={sectionData.name}
                                error={error?.name}
                                helperText={error?.name || ''}
                                onChange={(e) => onFieldChange('name', e.target.value)}
                                label="Name"
                                placeholder="Please enter Name"
                                onFocus={() => setError({ ...error, name: '' })}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className='select-template-btn' onClick={() => setAddKeywords({ modal: true, data: sectionData })}>
                                Select Keywords from Template
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ margin: '-15px 0px 0px' }}>
                                <AutocompleteCreatable
                                    placeholder={sectionData.keywords?.length ? '' : "Click on Select from Template or type to add new"}
                                    options={[]}
                                    value={sectionData.keywords || []}
                                    paramsKey='keywords'
                                    optionKey="name"
                                    required={true}
                                    multiple={true}
                                    label="Keywords"
                                    error={error?.keywords}
                                    helperText={error?.keywords || ''}
                                    resetErrorKey={() => setError({ ...error, keywords: '' })}
                                    onFieldChange={(paramsKey, value, type) => onFieldChange('keywords', value, type)}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="description"
                                fullWidth
                                margin="normal"
                                multiline={true}
                                rows={3}
                                variant='outlined'
                                inputProps={{ maxLength: 250 }}
                                value={sectionData.description}
                                onChange={(e) => onFieldChange('description', e.target.value)}
                                label="Description"
                                placeholder="Please enter Description"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        {/* {
                            revAssetTypeValues[props?.match?.params?.assetType]?.assetType == 1 ?
                                <Grid item xs={12}>
                                    <Autocomplete
                                        style={{ paddingTop: "13px", marginBottom: '10px' }}
                                        options={maAssets}
                                        getOptionLabel={option => option.label}
                                        getOptionSelected={(option, value) => option.value === value.value }
                                        value={ maAssets.find(item => item?.value == sectionData?.major_assembly_asset_type?.value ) || null}
                                        onChange={(e, data) => onFieldChange('major_assembly_asset_type', data)}
                                        renderInput={params => <TextField variant='outlined' onFocus={() => setError({ ...error, major_assembly_asset_type: '' })} error={error?.major_assembly_asset_type} helperText={error?.major_assembly_asset_type || ''} placeholder="Please select Major Assembly Type" size='small' {...params} margin="none" label='Major Assembly Type' fullWidth InputLabelProps={{ shrink: true }} />}
                                    />
                                </Grid>
                                : null}
                        {
                            revAssetTypeValues[props?.match?.params?.assetType]?.assetType == 1 ?
                                <Grid item xs={12}>
                                    <Autocomplete
                                        style={{ paddingTop: "13px", marginBottom: '10px' }}
                                        options={maTypes || []}
                                        disabled={sectionData?.major_assembly_asset_type ? false : true}
                                        getOptionLabel={option => option.major_assembly_name}
                                        value={maTypes?.find(item => item?.id == sectionData?.major_assembly_asset?.id )  || null}
                                        onChange={(e, data) => onFieldChange('major_assembly_asset', data)}
                                        renderInput={params => <TextField variant='outlined' onFocus={() => setError({ ...error, major_assembly_asset: '' })} error={error?.major_assembly_asset} helperText={error?.major_assembly_asset || ''} placeholder={assemblyLoader ?'Fetching the List...'  :"Please select Major Assembly Asset"} size='small' {...params} margin="none" label='Major Assembly Asset' fullWidth InputLabelProps={{ shrink: true }} />}
                                    />
                                </Grid> : null} */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant='outlined' color="primary" onClick={() => toggleModal(false)}>Cancel</Button>
                    <Button size='small' onClick={() => saveSectionData(sectionData)} variant="contained" color="primary">Save</Button>
                    { checkPermission('records', 'mini-pack', 'U') ?
                    <Button size='small' onClick={() => saveSectionData(sectionData,'sync')} variant="contained" color="primary">Save & Sync</Button>
                    : null}
                </DialogActions>
            </Dialog>
            {
                addKeywords?.modal ?
                    <SectionFolderSelection
                        keywordsCreated={keywordsCreated}
                        addKeywords={addKeywords}
                        toggleModalFn={() => { setAddKeywords(false); setKeywordsCreated([]) }}
                        submitWithTemplate={(data, payload) => onSubmitKeyWdData(data, payload)}
                    /> : null
            }
        </>
    )
}