import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { globalGetService, globalExportService, globalPostService } from '../../../utils/globalApiServices';
import { checkPermission, checkApiStatus, getLocalStorageInfo, downloadFileType, getFileSize, getFileIcon } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { Pagination, FilterComponent, ExportManu, PageLoader, EmptyCollection, DeletePopUp } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { getAllAssetListAc, getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import folderIcon from '../../../assets/images/folder_icon.svg';
import { Table, TableBody, Paper, Grid, Button, Tooltip, Checkbox, TableHead, TableRow, TableCell, Avatar } from '@material-ui/core';
import config from '../../../config';
import { assetTypeValues, displayDateFormatShort } from '../../../constants';
import { trashFilterOps } from '../';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
const TrashHd = ({toggleBulkOps, bulkFlag}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>
                    <Checkbox
                        color="primary"
                        size="small"
                        onChange={(e) => toggleBulkOps(e.target.checked)}
                        checked={bulkFlag}
                    />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Asset</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Deleted By</TableCell>
                <TableCell>Deleted At</TableCell>
                <TableCell align='right'>Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
const TrashList = ({item, bulkOperation, toggleBulkOps, trashSingleActionFn, fileIcons}) => {
    const selectedItem = bulkOperation.ids.find(data => data.uuid === item.uuid);
    return(
        <TableRow hover tabIndex={-1}>
            {checkPermission('trash','trash','RSTOR') || checkPermission('trash','trash','D')  ?
                <TableCell width="30">
                    <Checkbox
                        name={item.id}
                        color="primary"
                        size="small"
                        onChange={(e) => toggleBulkOps(e.target.checked)}
                        checked={selectedItem ? true : false}
                    />
                </TableCell> : null
            }
            <TableCell className="file-info" width="380" style={{ cursor: 'pointer' }} onClick={(e) => item.type !== 'folder' ? window.open(`/records/preview/${item.uuid}?trash=true`, '_blank') : e.preventDefault()}>
                <div className="flex-centered">
                    <span style={{width:'30px', display:'inline-block'}}><img width={item.type === 'folder' ? "30" : "20"} src={item.type === 'folder' ? folderIcon : getFileIcon(fileIcons, item.extension)} alt="icon" /></span>
                    <div style={{marginLeft:'5px'}}>
                        <h5>{item.name}</h5>
                        <p>{item.location}</p>
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <div className="asset-info">
                    {item.asset && item.asset.type ? <p>{item.asset.type}</p> : null}
                    <h5>{item && item.asset && assetTypeValues && item.asset.asset_type_number && assetTypeValues[item.asset.asset_type_number].label ? assetTypeValues[item.asset.asset_type_number].label : ''} {item.asset && item.asset.serial_no ? item.asset.serial_no : '--'}</h5>
                </div>
            </TableCell>
            <TableCell>
                <div>
                    {item.type === 'folder' ? item.file_count === null ? "0 Files" : item.file_count + " Files" : getFileSize(item.size)}
                </div>
            </TableCell>
            <TableCell>
                { item.modified_by && item.modified_by.name ?
                    <div className="flex-centered">
                        <Avatar style={{ float: 'left', marginRight: '6px' }} alt="Profile Picture" src={item.modified_by.profile_pic} />
                        {item.modified_by.name}
                    </div>:
                item.created_by && item.created_by.name ?
                    <div className="flex-centered">
                        <Avatar style={{ float: 'left', marginRight: '6px' }} alt="Profile Picture" src={item.created_by.profile_pic} />
                        {item.created_by.name}
                    </div>
                :null
                }
            </TableCell>
            <TableCell>
                <div>
                    {item.modified ? moment(item.modified).format(displayDateFormatShort) : moment(item.created).format(displayDateFormatShort)}
                </div>
            </TableCell>
            <TableCell align='right'>
                <ul className='list-inline mobile-cta'>
                    {checkPermission('trash','trash','RSTOR') ?
                        <li className='list-inline-item' style={{cursor:'pointer'}}>
                            <Tooltip title="Restore" arrow>
                                <SettingsBackupRestoreIcon onClick={() => { trashSingleActionFn('Restore'); trackActivity('Item Clicked', { page_title: 'Trash', event_type: 'Restore', event_desc: 'Clicked on Restore Icon' }) }} color="primary" />
                            </Tooltip>
                        </li>:null
                    }
                    { checkPermission('trash','trash','D') ?
                        <li className='list-inline-item' style={{cursor:'pointer'}}>
                            <Tooltip title="Delete" arrow>
                                <DeleteIcon onClick={() => { trashSingleActionFn('Delete'); trackActivity('Item Clicked', { page_title: 'Trash', event_type: 'Delete', event_desc: 'Clicked on Delete Icon' }) }} color="secondary" />
                            </Tooltip>
                        </li>:null
                    }
                </ul>
            </TableCell>
    </TableRow>
    )
}
const Trash = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [usersFilter, setUsersFilter] = useState([]);
    const [trashInfo, setTrashInfo] = useState({list:[], pagination:{}});
    const [bulkOperation, setBulkOperation] = useState({modal:false, ids:[], title:'', type:''})
    const allAssets = useSelector(state => state?.shareReducer?.allAssets||[])
    const pltConstants = useSelector(state => state?.shareReducer?.pltConstants||[])
    const dispatch = useDispatch();
    useEffect(() => {
        getUserFilterApi()
        getTrashInfo({}, 'skeletonLoader');
        dispatch(getAllAssetListAc())
        dispatch(getPlatformConstantsAc({ constant_types: ['file_extension_drive'] }))
    },[])
    const getTrashInfo = (queryParams, loaderType) => {
        loaderType === 'skeletonLoader'? setSkeletonLoader(true):setLoading(true)
        let query = Object.assign({}, queryParams);
        if (query.asset) {
          let filterAsset = query.asset.split('_');
          query = { ...query, asset_type: filterAsset[1], asset_id: filterAsset[0] }
          delete query.asset
        }
        globalGetService('records/v2/trash/get-deleted-files/', query)
        .then(response => {
            if(checkApiStatus(response)){
                delete queryParams.page;
                delete queryParams.per_page;
                setTrashInfo(response.data.data);
                setFilter(queryParams);
            }
            loaderType === 'skeletonLoader'? setSkeletonLoader(false):setLoading(false)
        })
    }
    const getUserFilterApi =()=>{
        globalGetService(`records/v2/trash/get-deleted-files/?get_only_users=true`)
        .then(response=>{
          if(checkApiStatus(response)){
            setUsersFilter(response.data.data)
          }
        })
    }
    const onExportTrashInfo = (queryParams) => {
        let query = Object.assign({}, queryParams);
        if (query.asset) {
            let filterAsset = query.asset.split('_');
            query = { ...query, asset_type: filterAsset[1], asset_id: filterAsset[0] }
            delete query.asset
        }
        setLoading(true);
        globalExportService('records/v2/trash/get-deleted-files/', query)
        .then(response => {
            downloadFileType(response.data, (`Trash_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), query.download);
            enqueueSnackbar("Trash List Exported successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            trackActivity('Item Exported', {
                page_title: 'Trash',
                event_type: 'Export',
                event_desc: 'Exported Trash data from Review Dock',
                extension: query.download
            });
            setLoading(false);
        })
    }
    const onChangeTrashStatus = () => {
        let payload = {};
        let file_uuid = bulkOperation.ids.filter(item => item.type === 'file').map(item => item.uuid);
        let folder_uuid = bulkOperation.ids.filter(item => item.type === 'folder').map(item => item.uuid);
        payload = { ...payload, action: bulkOperation.title === 'Delete' ? 1 : 2, }
        if (file_uuid.length) { payload = { ...payload, file_uuid: file_uuid, } }
        if (folder_uuid.length) { payload = { ...payload, folder_uuid: folder_uuid, } }
        setLoading(true);
        globalPostService(`records/v2/trash/action/`, payload)
        .then(response => {
            if(checkApiStatus(response)){
                setBulkOperation({modal:false, ids:[], title:'', type:''});
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                getTrashInfo({}, 'pageLoader');
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
            setLoading(false);
        })
    }
    const toggleBulkOps = (flag, type, item) => {
        if(type === 'bulk'){
            setBulkOperation({...bulkOperation, type:'bulk', ids:flag ? trashInfo.list :[]});
        }else{
            setBulkOperation({...bulkOperation, type:'bulk', ids:flag ? [...bulkOperation.ids, item]:bulkOperation.ids.filter(optionItem => optionItem.uuid !== item.uuid)})
        }
    }
    const trashSingleActionFn = (title, item) => {
        setBulkOperation({ ...bulkOperation, title, modal:true, type:'single', ids:[item] })
    }
    let filterOptions = Object.assign({}, trashFilterOps);
    filterOptions = {
      ...filterOptions,
      asset: {
        ...filterOptions.asset,
        options: allAssets.sort((a, b) => a.unique_name !== b.unique_name ? a.unique_name < b.unique_name ? -1 : 1 : 0).map((item) => { return { ...item, id: `${item.id}_${item.asset_type}` } })
      },
      user_id:{
        ...filterOptions.user_id,
        options:usersFilter
      }
    }
    return(
        <section className='fleets-management'>
            <DeploymentMessage />
            { skeletonLoader ? <STableLoader count={9} />:
                <>
                    <Paper square style={{padding:'5px 10px', position:'relative'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <FilterComponent 
                                    filter={filter} 
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getTrashInfo({...applyFilter, page:1, per_page:trashInfo.pagination.per_page}, 'pageLoader')}
                                />
                            </Grid>
                            { checkPermission('reports','activities','EXP') ?
                                <Grid item xs={4}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        <li className='list-inline-item'>
                                            <ExportManu 
                                                exportReportFn={(file) => onExportTrashInfo({...filter, download:file.extension})}
                                                files={[{title:'EXCEL', extension:'xlsx'}]}
                                            />
                                        </li>
                                    </ul>
                                </Grid>:null
                            }
                        </Grid>
                        { bulkOperation?.type === 'bulk' && bulkOperation?.ids?.length ?
                            <div className='flex-centered' style={{position:'absolute', top:'0', left:'0', width:'100%', bottom:'0', zIndex:9, background:'#fff', padding:'0 15px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <ul className='list-inline flex-centered'>
                                            <li className="list-inline-item" style={{cursor:'pointer'}} onClick={() => setBulkOperation({modal:false, ids:[], title:'', type:''})}>
                                                <Tooltip title='Cancel' arrow><CloseIcon color='primary' /></Tooltip>
                                            </li>
                                            { checkPermission('trash','trash','RSTOR') ?
                                                <li className="list-inline-item" style={{cursor:'pointer'}}>
                                                    <Button onClick={() => setBulkOperation({...bulkOperation, title:'Restore', modal:true})} size="small" variant="outlined" color="primary">Restore ({bulkOperation.ids.length})</Button>
                                                </li>:null
                                            }
                                            { checkPermission('trash','trash','D') ?
                                                <li className="list-inline-item" style={{cursor:'pointer'}}>
                                                    <Button onClick={() => setBulkOperation({...bulkOperation, title:'Delete', modal:true})}  size="small" variant="outlined" color="secondary">Delete ({bulkOperation.ids.length})</Button>
                                                </li>:null
                                            }
                                        </ul>
                                    </Grid>
                                </Grid>
                            </div>:null
                        }
                    </Paper>
                    <Paper className='major-assemblies-list'>
                        <div style={{height: !trashInfo.list.length ? 'auto':`${window.innerHeight-180}px`,overflow:'auto'}}>
                            <Table className='mui-table-format' stickyHeader={true}>
                                <TrashHd 
                                    toggleBulkOps={(flag) => toggleBulkOps(flag, 'bulk')} 
                                    bulkFlag={trashInfo.list.length === bulkOperation.ids.length && trashInfo.list.length > 0}
                                />
                                <TableBody>
                                    {trashInfo.list.map((item,index) =>
                                        <TrashList 
                                            bulkOperation={bulkOperation}
                                            item={item} 
                                            fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                                            toggleBulkOps={(flag) => toggleBulkOps(flag, 'single', item)}
                                            trashSingleActionFn={(title) => trashSingleActionFn(title, item)}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination 
                            pagination={trashInfo.pagination}
                            onChangePage={(event, newPage) => getTrashInfo({...filter, page:newPage+1, per_page: trashInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getTrashInfo({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        {!trashInfo.list.length ? <div style={{textAlign:'center'}}><EmptyCollection title={'No records found'} /></div>:null}
                    </Paper>  
                </>
            }
            {bulkOperation.modal ?
                <DeletePopUp
                    modal={bulkOperation.modal}
                    toggleModalFn={() => setBulkOperation({modal:false, ids:[], title:'', type:''})}
                    title={bulkOperation?.title||''}
                    content={<h4>{`Are you sure you want to ${bulkOperation?.title||''}?`}</h4>}
                    triggerEventFn={onChangeTrashStatus}
                    confirmText={bulkOperation.title}
                /> : null
            }
            { isLoading ? <PageLoader/>:null}
        </section>
    )
}
export default Trash;