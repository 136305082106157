import React, { Component, useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { Button, Grid, Table, TableBody, Paper, Tooltip, Switch, Dialog, DialogActions, DialogContent, DialogTitle, Chip, TextField, TableRow, TableCell, TableHead, Hidden, Checkbox } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { PageLoader, NoAccess, TableListComp, DeletePopUp, EmptyCollection, Pagination, FilterComponent } from '../../../shared_elements';
import { DropzoneArea } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';
import { globalPostService, globalPutService, globalDeleteService, globalGetService, globalExportService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { checkPermission } from '../../../utils';
import { STableLoader } from '../../../shared_elements/loaders';
import MobileSecondarySideNav from '../../dataroom/components/MobileSecondarySideNav';
import { templateFilterOptions } from '..';
import { Autocomplete } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReadMoreInline from '../../../shared_elements/components/ReadMoreInline';
import { Info } from '@material-ui/icons';
import axios from 'axios';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
const Templates = ({ props }) => {
  const [file_auto_publish, setFile_auto_publish] = useState(false)
  const [pageLoader, setPageLoader] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    trackActivity('Page Visited', {
      page_title: 'Settings(Data Room Templates)'
    })
  }, [])
  const autoPublishSettingApi = (props, data) => {
    setPageLoader(true)
    globalPostService(`records/v2/auto-file-publish/`, data)
      .then(response => {
        setPageLoader(false)
        if (checkApiStatus(response)) {
          setFile_auto_publish(data.file_auto_publish)
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  }

  if (checkPermission('settings', 'enable_auto-publish_file(s)', 'R') || checkPermission('settings', 'records_template', 'R')) {
    return (
      <section className="records-templates">
        <DeploymentMessage />
        <Hidden only={['lg', 'xl']} ><MobileSecondarySideNav /></Hidden>
        {checkPermission('settings', 'enable_auto-publish_file(s)', 'R') ?
          <div className="heading">
            <h3>Data Room Templates</h3>
            <p>
              {file_auto_publish ? 'Disable' : 'Enable'} Auto-Publish File(s)
              <Switch
                disabled={!checkPermission('settings', 'enable_auto-publish_file(s)', 'U')}
                checked={file_auto_publish}
                onChange={(e) => { autoPublishSettingApi(props, { file_auto_publish: e.target.checked }); trackActivity('Item Clicked', { page_title: 'Settings', event_type: 'Toggle bar switched', event_desc: ' Switched the Auto Publish Toggle bar from Records Template in Settings Page ' }) }}
                name="checkedB"
                color="primary"
              />
            </p>
            {!file_auto_publish ? <span className="notes">Allow Auto-Publish will help to reduce the manual approval process</span> : null}
          </div> : null
        }


        <TemplateListing
        />
        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  } else {
    return <NoAccess />
  }
}
const TemplateHD = ({ }) => {
  return (
    <TableHead style={{position:"sticky", top:'0'}}>
      <TableRow>
        <TableCell width='250px'>Name</TableCell>
        <TableCell>Auto Apply</TableCell>
        <TableCell>Asset Type</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Created By</TableCell>
        <TableCell>Updated By</TableCell>
        <TableCell align='right'>Action</TableCell>
      </TableRow>
    </TableHead>
  )
}
const TemplateListItem = ({ listItem, setAddEdit, setDeleteModal, downloadTemplate }) => {
  let  titleItems = listItem?.asset_type?.value == 1 ? listItem?.aircraft_types :listItem?.asset_type?.value == 2 ? listItem?.engine_types : listItem?.apu_types
 let tooltipContent=  titleItems?.length && titleItems?.map((item, index)=>{return index == 0 ? item.name :', '+ item.name})
  return (
    <TableRow>
      <TableCell>
        {listItem?.name || '--'}
      </TableCell>
      <TableCell>
        {listItem?.auto_apply ? 
        // <CheckCircleOutlinedIcon style={{color:"#238613"}} />
        'Enabled'
        : 'Disabled'}
      </TableCell>
      <TableCell>
        <span style={{display:'flex', alignItems:'center'}}>
        {listItem?.asset_type?.label || '--'}
        {
          tooltipContent?.length ?
          <span>
            <Tooltip title={tooltipContent}>
              <span>
                <Info style={{ marginLeft: '2px', height: '14px' }}/>
              </span>
            </Tooltip>
          </span> : null
        }
        </span>
      </TableCell>
      <TableCell style={{maxWidth:'310px'}}>
        <ReadMoreInline text={listItem?.description}/>
      </TableCell>
      <TableCell>
        {listItem?.created_by?.name || '--'}
      </TableCell>
      <TableCell>
        {listItem?.modified_by?.name || '--'}
      </TableCell>
      <TableCell>
        <ul className='list-inline' style={{ float: 'right' }}>
          <li className='list-inline-item' style={{ cursor: 'pointer' }}>
            <Tooltip title={`Download Attachment ${listItem.name}`}>
              <GetAppIcon onClick={() => downloadTemplate(listItem)} fontSize='small' color='primary' />
            </Tooltip>
          </li>
            {
              checkPermission ('settings','records_template','U') ?
              <li className='list-inline-item' style={{ cursor: 'pointer' }}>
            <EditIcon onClick={() => setAddEdit(listItem)} fontSize='small' color='primary' />
          </li>
          : null}
          {
                checkPermission ('settings','records_template','D') ?
          <li className='list-inline-item' style={{ cursor: 'pointer' }}>
            <DeleteOutlineIcon onClick={() => setDeleteModal({ modal: true, data: listItem })} fontSize='small' color='secondary' />
          </li>
          : null}
        </ul>
      </TableCell>
    </TableRow>)
}

const TemplateListing = () => {
  const [listData, setListData] = useState([])
  const [addEditModal, setAddEditModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [skeletonLoader, setSkeletonLoading] = useState(false)
  const [templateData, setTemplateData] = useState({})
  const [filter, setFilter] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [filterAircraftTypes, setFilterAircraftTypes] = useState([]);
  const [filterEngineTypes, setFilterEngineTypes] = useState([]);
  const [apuList, setApuList] = useState([]);
  const [deleteModal, setDeleteModal] = useState({});
  const [autoApplyConfirm, setAutoApplyConfirm] = useState({});
  useEffect(() => {
    getTemplateList()
    getAircraftTypes();
    getEngineTypes();
    getApuList();
  }, [])
  const getTemplateList = (query) => {
    setSkeletonLoading(true)
    globalGetService(`records/v2/lessor-folder-template/`, query)
      .then(response => {
        setSkeletonLoading(false)
        if (checkApiStatus(response)) {
          setListData(response.data.data)
          if (query) {
            delete query.page
            setFilter(query);
          }
        }
      })
  }
  const validateAutoApply =()=>{
    let payload={}
    payload ={
      ...payload,
      asset_type:templateData?.asset_type || null,
      aircraft_types:templateData?.aircraft_types || null,
      apu_types:templateData?.apu_types || null,
      engine_types:templateData?.engine_types || null,
      lessor_template_id:templateData?.id || null
    }
    setLoading(true)
    globalPostService(`records/v2/validate-auto-apply/`,payload)
    .then(response=>{
      setLoading(false)
      if(!checkApiStatus(response) && response?.data?.statusCode == 500){
        setAutoApplyConfirm({modal:true, data:response.data.message})
      }
    })
  }
  const onFieldChange = (key, value) => {
    if(key == 'auto_apply' && value){
      validateAutoApply()
    }
    if (key == 'template_sheet') {
      setTemplateData(prevState=> ({ ...prevState, file_name: null, [key]: value  }))
    }
    else{
      if(key =='auto_apply'){
        setTemplateData({ ...templateData, [key]: value})
      }
      else{
        setTemplateData({ ...templateData, [key]: value, auto_apply:false })
      }
    }
  }
  const onDeleteAttachment =()=>{
    setTemplateData(prevState=>({
      ...prevState,
      file_name:null,
      template_sheet:null
    }))
  }
  const setAddEdit = (listItem) => {
    if (listItem) {
      setLoading(true)
      globalGetService(`records/v2/lessor-folder-template/${listItem?.id}/`)
        .then(response => {
          setLoading(false)
          if (checkApiStatus(response)) {
            setTemplateData(response.data.data)
            setAddEditModal(true)
          }
        })
    }
    else {
      setTemplateData({})
      setAddEditModal(true)
    }
  }
  const onDelete = (data) => {
    setLoading(true)
    globalDeleteService(`records/v2/lessor-folder-template/${data?.id}/`)
      .then(response => {
        setLoading(false)
        setDeleteModal({ modal: false, data: null })
        if (checkApiStatus(response)) {
          getTemplateList()
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
        }
        else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
        }
      })
  }
  const getAircraftTypes = () => {
    globalGetService(`console/aircraft-types/`,)
      .then(response => {
        if (checkApiStatus(response)) {
          setFilterAircraftTypes(response.data.data.aircraftTypes);
        }
      })
  }
  const getEngineTypes = () => {
    globalGetService(`console/engine-types/`,)
      .then(response => {
        if (checkApiStatus(response)) {
          setFilterEngineTypes(response.data.data.engineTypes)
        }
      })
  }
  const getApuList = () => {
    globalGetService(`/console/apu-types/`)
      .then(response => {
        if (checkApiStatus(response)) {
          setApuList(response.data.data.apu_types.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0))
        }
      })
  }
  const exportListFn = () => {
    setLoading(true)
    globalExportService(`records/v2/lessor-folder-template/`, { download: true, ...filter })
      .then(response => {
        setLoading(false)
        downloadFileType(response.data, (`Records Template Listing`), 'xlsx');
        enqueueSnackbar("File has been Exported Successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      })
  }
  const downloadTemplate = (item) => {
    setLoading(true)
    globalExportService(`records/v2/lessor-folder-template/download/${item?.id}/`)
      .then(response => {
        setLoading(false)
        downloadFileType(response.data, (`${item?.name}`), 'xlsx');
      })
  }
  let filterOptions = templateFilterOptions;
  filterOptions = {
    ...filterOptions,
    aircraft_types: {
      ...filterOptions.aircraft_types,
      options: filterAircraftTypes
    },
    engine_types: {
      ...filterOptions.engine_types,
      options: filterEngineTypes
    },
    apu_types: {
      ...filterOptions.apu_types,
      options: apuList
    },
  }
  return (
    <div>
      {isLoading ? <PageLoader /> : null}
      {skeletonLoader ? <STableLoader count={4} /> :
        <Paper>
          <Grid container spacing={1}>
            <Grid item md={9} sm={9} xs={9}>
              <FilterComponent
                filter={filter}
                filterMenu={filterOptions}
                getResponseBack={(applyFilter) => getTemplateList({ ...applyFilter, page: 1 }, 'pageLoader')}
              />
            </Grid>
            <Grid item xs={3}>
              {
                checkPermission('settings', 'records_template', 'EXP') ?
                  <Button variant='outlined' color='primary' style={{ float: "right", margin: '5px 10px' }} size='small' onClick={() => exportListFn()}>
                    Export
                  </Button>
                  : null}
              {
                checkPermission ('settings','records_template','C') ?
                <Button variant='contained' color='primary' style={{ float: "right", margin: '5px 0px' }} size='small' onClick={() => setAddEdit()}>
                Add Template
              </Button>
              : null}
            </Grid>
          </Grid>
          <Paper  style={{height:`${window.innerHeight-270}px`,overflow: 'scroll', borderRadius:'0px'}}>
            <Table className='mui-table-format'>
              <TemplateHD />
              <TableBody>
                {listData?.list?.map(listItem => (
                  <TemplateListItem
                    downloadTemplate={downloadTemplate}
                    setAddEdit={setAddEdit}
                    listItem={listItem}
                    setDeleteModal={setDeleteModal}
                  />
                ))}
              </TableBody>
            </Table>
            {!listData?.list?.length && !isLoading && !skeletonLoader ? <EmptyCollection title='No records found' /> : null}
          </Paper>
            <Pagination
              pagination={listData.pagination}
              onChangePage={(event, newPage) => getTemplateList({ ...filter, page: newPage + 1, per_page: listData?.pagination?.per_page || 20 }, 'pageLoader')}
              onChangeRowsPerPage={(event) => getTemplateList({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
            />
        </Paper>
      }
      {
        addEditModal ?
          <AddEditTemplate
            filterEngineTypes={filterEngineTypes}
            filterAircraftTypes={filterAircraftTypes}
            apuList={apuList}
            getResponseBack={getTemplateList}
            onFieldChange={onFieldChange}
            templateData={templateData}
            open={addEditModal}
            setAddEditModal={setAddEditModal}
            onDeleteAttachment={onDeleteAttachment}
          />
          : null
      }
      {
        deleteModal?.modal ?
          <DeletePopUp
            modal={deleteModal.modal}
            toggleModalFn={() => setDeleteModal({ modal: false, data: null })}
            title="Delete Template"
            content={<h4>Are you sure you want to delete?</h4>}
            triggerEventFn={() => onDelete(deleteModal?.data)}
          /> : null
      }
      {
        autoApplyConfirm?.modal ?
          <DeletePopUp
          confirmColor='primary'
          confirmText='Continue'
            modal={autoApplyConfirm.modal}
            toggleModalFn={() => {setAutoApplyConfirm({ modal: false }); onFieldChange('auto_apply',false) }}
            title="Confirm Auto Apply Template"
            content={<span> 
              <h4 style={{marginBottom:"20px"}}>{autoApplyConfirm?.data} </h4>
              <span className='records-templates'>
                <div className='heading' style={{marginBottom:"30px"}}>
                  <span className='notes' style={{fontSize:'11px'}}>
                    Even with Autoapply enabled, you can still choose different templates when adding the asset.
                  </span>
                </div>
              </span>
              </span>}
            triggerEventFn={() => setAutoApplyConfirm({ modal: false })}
          /> : null
      }
    </div>
  )
}
const AddEditTemplate = ({ setAddEditModal, open, templateData, onFieldChange, getResponseBack, filterEngineTypes, apuList, filterAircraftTypes, onDeleteAttachment }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState({});
  const saveTemplate = (data) => {
    const validationInputs = {
      name: data?.name?.trim()?.length ? '' : 'Please enter Name',
      asset_type: data?.asset_type?.value ? '' : 'Please select Asset Type',
      template_sheet: data?.template_sheet?.length || data?.file_name ? '' : 'Please upload File'
    }
    let formData = new FormData()
    formData.append('name', data.name)
    formData.append('auto_apply', data.auto_apply ? true : false)
    if (data?.asset_type?.value) {
      formData.append('asset_type', data.asset_type?.value)
      if (data?.aircraft_types?.length && data.asset_type?.value == 1) {
        formData.append('aircraft_types', data.aircraft_types?.map(item => item?.id))
      }
      else if (data?.engine_types?.length && data.asset_type?.value == 2) {
        formData.append('engine_types', data.engine_types?.map(item => item?.id))
      }
      else if (data?.apu_types?.length && data.asset_type?.value == 3) {
        formData.append('apu_types', data.apu_types?.map(item => item?.id))
      }
    }
    formData.append('description', data.description || '')
    if (data?.template_sheet && data?.template_sheet[0] && data?.template_sheet[0] != 'h') {
      formData.append('template_sheet', data.template_sheet[0])
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      setLoading(true)
      if (data?.id) {
        globalPutService(`records/v2/lessor-folder-template/${data?.id}/`, formData)
          .then(response => {
            setLoading(false)
            if (checkApiStatus(response)) {
              getResponseBack()
              setAddEditModal(false)
              enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
            else {
              enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
          })
      }
      else {
        globalPostService(`records/v2/lessor-folder-template/`, formData)
          .then(response => {
            setLoading(false)
            if (checkApiStatus(response)) {
              getResponseBack()
              setAddEditModal(false)
              enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
            else {
              enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
          })
      }
    }
    else {
      setError(validationInputs)
      enqueueSnackbar('Please fill the required fields', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
    }

  }
  const downloadSample = () => {
    window.open('https://sparta-aims.ams3.digitaloceanspaces.com/media/folder_structure/Sample_Template/Sample_Dataroom_Template.xlsx')
  }
  const downloadTeplate  = (url, name) => {
    axios.get(url, { responseType: 'arraybuffer', })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click()
      })
      .catch(error => this.setState({ pageLoader: false }))
  }
  return (
    <div>
      {
        isLoading ? <PageLoader /> : null
      }
      <Dialog
        open={open}
        onClose={() => { setAddEditModal(false) }}
        aria-labelledby="scroll-dialog-title"
        className='records-dataroom-modal'
        // fullWidth='true'
      >
        <DialogTitle id="scroll-dialog-title">
          {templateData?.id ? 'Edit' : 'Add'} Template
        </DialogTitle>
        <DialogContent dividers={true} style={{width:"500px"}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                required
                id="name"
                fullWidth
                margin="normal"
                variant='outlined'
                inputProps={{ maxLength: 50 }}
                value={templateData.name}
                error={error?.name}
                helperText={error?.name || ''}
                onChange={(e) => onFieldChange('name', e.target.value)}
                label="Name"
                placeholder="Please enter Name"
                onFocus={() => setError({ ...error, name: '' })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                style={{ paddingTop: "13px"}}
                disabled={templateData?.id}
                options={[{ label: 'Aircraft', value: 1 }, { label: 'Engine', value: 2 }, { label: 'APU', value: 3 }, { label: 'Landing Gear', value: 4 }, { label: 'Propeller', value: 5 }, {label:'Engine Modules', value:10}]}
                getOptionLabel={option => option.label}
                value={templateData?.asset_type}
                onChange={(e, data) => onFieldChange('asset_type', data)}
                renderInput={params => <TextField required variant='outlined' onFocus={() => setError({ ...error, asset_type: '' })} error={error?.asset_type} helperText={error?.asset_type || ''} placeholder="Please select Asset Type" size='small' {...params} margin="none" label='Asset Type' fullWidth InputLabelProps={{ shrink: true }} />}
              />
            </Grid>

            {
              templateData?.asset_type?.value == 1 ?
                <Grid item xs={12}>
                  <Autocomplete
                    style={{ paddingTop: "13px" }}
                    options={filterAircraftTypes || []}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option.id === value.id }
                    multiple={true}
                    value={templateData?.aircraft_types || []}
                    onChange={(e, data) => onFieldChange('aircraft_types', data)}
                    renderInput={params => <TextField variant='outlined' placeholder={templateData?.aircraft_types?.length ? '' : "Please select Aircraft Type"} size='small' {...params} margin="none" label='Aircraft Type' fullWidth InputLabelProps={{ shrink: true }} />}
                  />     </Grid> :
                templateData?.asset_type?.value == 2 ?
                  <Grid item xs={12}>
                    <Autocomplete
                      style={{ paddingTop: "13px" }}
                      multiple={true}
                      options={filterEngineTypes || []}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, value) => option.id === value.id }
                      value={templateData?.engine_types || []}
                      onChange={(e, data) => onFieldChange('engine_types', data)}
                      renderInput={params => <TextField variant='outlined' placeholder={templateData?.engine_types?.length ? '' : "Please select Engine Type"} size='small' {...params} margin="none" label='Engine Type' fullWidth InputLabelProps={{ shrink: true }} />}
                    /> </Grid> :
                  templateData?.asset_type?.value == 3 ?
                    <Grid item xs={12}>
                      <Autocomplete
                        style={{ paddingTop: "13px" }}
                        multiple={true}
                        options={apuList || []}
                        getOptionSelected={(option, value) => option.id === value.id }
                        getOptionLabel={option => option.name}
                        value={templateData?.apu_types || []}
                        onChange={(e, data) => onFieldChange('apu_types', data)}
                        renderInput={params => <TextField variant='outlined' placeholder={templateData?.apu_types?.length ? '' : "Please select APU Type"} size='small' {...params} margin="none" label='APU Type' fullWidth InputLabelProps={{ shrink: true }} />}
                      />  </Grid> : null
            }
            <Grid item xs={12}>
              <TextField
                id="description"
                fullWidth
                margin="normal"
                multiline={true}
                rows={3}
                variant='outlined'
                value={templateData.description}
                onChange={(e) => onFieldChange('description', e.target.value)}
                label="Description"
                inputProps={{ maxLength: 250 }}
                placeholder="Please enter Description"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <div style={{ marginLeft: '-10px', marginTop: '-6px', display: 'inline-table' }}>
                <Checkbox
                  color="primary"
                  className="checkbox-icon"
                  size="small"
                  onChange={(e) => onFieldChange('auto_apply', e.target.checked)}
                  checked={templateData?.auto_apply || false}
                /> <span style={{ fontWeight: '600', fontSize: "13px" }}> Auto Apply</span></div>
              <span style={{ float: "right" }}>
                Refer the sample template
                <Button style={{ fontFamily: '"Conv_IBMPlexSans-Bold"', fontWeight: '700' }} size='small' color='primary' variant='text' aria-controls="simple-menu" aria-haspopup="true" onClick={downloadSample} endIcon={<ArrowDropDownIcon fontSize='small' />}>
                  Download
                </Button>
              </span>
            </Grid>
            <Grid item xs={12}>
              <div className="dropzone-area">
                <DropzoneArea
                  filesLimit={1}
                  acceptedFiles={['.xlsx']}
                  maxFileSize={1000000}
                  showPreviewsInDropzone={false}
                  showPreviews={true}
                  useChipsForPreview={true}
                  dropzoneText={<p>Drag & Drop files<br /> OR <br /> Click Here</p>}
                  dropzoneClass="drag-drop-cnt"
                  maxWidth="sm"
                  showAlerts={['error', 'info']}
                  onDelete={(e) => {onFieldChange( 'template_sheet', null);}}
                  alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                  onDrop={(files) => { onFieldChange('template_sheet', files); setError({ ...error, template_sheet: '' }) }}
                />
                <p className="file-upload-note">Only .xlsx File is supported</p>
                {error?.template_sheet ? <p className='error-msg'>{error?.template_sheet}</p> : null}
              </div>
              {templateData?.id && templateData.file_name ?
                <Chip onDelete={()=>onDeleteAttachment('template_sheet', null)} onClick={() => downloadTeplate(templateData?.template_sheet,templateData?.file_name)} size='small' variant='outlined' label={templateData.file_name || '--'} color='primary' /> : null
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size='small' variant='outlined' color="primary" onClick={() => setAddEditModal(false)}>Cancel</Button>
          <Button size='small' onClick={() => saveTemplate(templateData)} variant="contained" color="primary">Save</Button>

        </DialogActions>
      </Dialog>
    </div>
  )
}
export default Templates
