import React, { useState, useEffect } from 'react';
import { Paper, Button, Dialog, Grid, TextField, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow, Tabs, Tab, FormControlLabel, Checkbox } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PageLoader } from '../../../shared_elements';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { revAssetTypeValues } from '../../../constants';
import { CheckForSameFolderName } from '..';
export default function SectionFolderSelection({ addKeywords, toggleModalFn, submitWithTemplate, templateData, keywordsCreated }) {
  const [templates, setTemplates] = useState([])
  const [checkedFolders, setCheckedFolders] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [maxObj, setMaxObj] = useState(0);
  const [importCrud, setImportCrud] = useState({ template: null });
  const [templateCrud, setTemplateCrud] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    getTemplateList()
  }, [])
  useEffect(() => {
    if (templateCrud.length > 0) {
      setMaxObj(findMaxDepth(templateCrud));
    }
  }, [templateCrud]);

  const getTemplateList = (query) => {
    setLoading(true)
    globalGetService(`records/v2/lessor-folder-template/`, { dropdown: true })
      .then(response => {
        setLoading(false)
        if (checkApiStatus(response)) {
          setTemplates(response.data.data.list)
          // let selectedTemplate = response.data.data?.list.find(item => item?.id == templateData?.templateDetails?.id)
          // setImportCrud({ template: selectedTemplate })
          // if (templateData?.folderDetails?.length > 1) {
          //   setTemplateCrud(templateData?.templateCrud);
          //   setCheckedFolders(templateData?.folderDetails.map(item=> ({...item, is_checked:false})))
          //   setImportCrud({ template: response.data.data.list?.find(item => item?.id == templateData?.templateDetails?.id) })
          // }
        }
        else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
      });
  }

  const onChangeTemplate = (template, apiCall) => {
    setLoading(true)
    setImportCrud({ template });
    globalGetService(`records/v2/lessor-folder-template/get-template-structure/${template?.id}/`)
      .then(response => {
        setLoading(false)
        if (checkApiStatus(response)) {
          let responseData = response.data?.data || []
          setTemplateCrud(responseData);
          responseData?.map(item => initialRender(item, item?.is_checked, apiCall))
        }
        else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
      })
  }
  const onFieldChange = (folder, checked, apiState) => {
    if ((checked == true && checkForDuplicateArr(folder)) ||checked== false ) {
    let initialData = [...checkedFolders]
    if (apiState == 'dropdown') {
      initialData = []
    }
    const updatedCheckedFolders = initialData;
    const toggleCheck = (folder, isChecked) => {
      const folderIndex = updatedCheckedFolders.findIndex(item => item.name === folder.name && item?.parent_folder == folder?.parent_folder);
      const folderData = {
        is_checked: isChecked,
        level: folder?.level,
        parent_folder: folder?.parent_folder,
        naming_conventions: folder?.naming_conventions,
        name: folder?.name,
        childrens: folder?.childrens
      };

      if (folderIndex === -1) {
        updatedCheckedFolders.push(folderData);
      } else {
        updatedCheckedFolders[folderIndex] = folderData;
      }
    };
    toggleCheck(folder, checked);
    setCheckedFolders(updatedCheckedFolders);
  }
  else{
    enqueueSnackbar('This folder name is already selected.', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
  }
  };
  const initialRender = (folder, checked, apiState) => {
    let initialData = [...checkedFolders]
    if (apiState == 'dropdown') {
      initialData = []
    }
    const updatedCheckedFolders = initialData;
    const toggleCheck = (folder, isChecked) => {
      const folderIndex = updatedCheckedFolders.findIndex(item => item.name === folder.name && item?.parent_folder == folder?.parent_folder);
      const folderData = {
        level: folder?.level,
        parent_folder: folder?.parent_folder,
        naming_conventions: folder?.naming_conventions,
        name: folder?.name,
        childrens: folder?.childrens
      };

      if (folderIndex === -1) {
        updatedCheckedFolders.push(folderData);
      } else {
        updatedCheckedFolders[folderIndex] = folderData;
      }
      folder.childrens.forEach(child => toggleCheck(child, child?.is_checked));
    };
    toggleCheck(folder, checked);
    if (apiState == 'apicall' || apiState == 'dropdown' || (checkedFolders?.length < 1 && addKeywords?.apiCall == 'apicall' && apiState != 'checkbox')) {
      setCheckedFolders(prevState => ([...prevState, ...updatedCheckedFolders]))
    }
    else {
      setCheckedFolders(updatedCheckedFolders);
    }
  };
  const onSubmitData = () => {
    let payload = {
      folderDetails: checkedFolders,
      templateCrud: templateCrud,
      templateDetails: importCrud.template,
    }
    submitWithTemplate(payload, importCrud)
  };
  const findMaxDepth = (folders) => {
    let maxDepth = 0;
    const traverse = (folders, depth) => {
      folders.forEach((folder) => {
        if (folder.childrens.length > 0) {
          traverse(folder.childrens, depth + 1);
        } else {
          maxDepth = Math.max(maxDepth, depth);
        }
      });
    };
    traverse(folders, 1);
    return maxDepth;
  };

  const renderRows = (folders, depth = 1, parentIndex = '') => {
    const maxDepth = findMaxDepth(templateCrud);
    return folders.flatMap((folder, index) => {
      const rows = [];
      const uniqueKey = `${parentIndex}-${index}-${folder.name}`; // Generate unique key
      const indent = Array.from({ length: depth - 1 }).map(() => (
        <TableCell key={Math.random()} />
      ));
      rows.push(
        <TableRow key={uniqueKey}>
          {indent}
          <TableCell>
            <FormControlLabel control={<Checkbox size="small" color='primary' checked={checkedFolders.find(item => (item.name === folder.name) && item?.is_checked == true && (item.parent_folder?.length ? item.parent_folder[0] === folder.parent_folder[0] : true))?.is_checked || false} onChange={(e) => onFieldChange(folder, e.target.checked, 'checkbox')} name="sub_folder" />} label={<p style={{ fontSize: '13px' }}>{folder.name}</p>} />
          </TableCell>
          {Array.from({ length: maxDepth - depth }).map((_, i) => (
            <TableCell key={`empty-${uniqueKey}-${i}`} />
          ))}
          {/* <TableCell>{folder.naming_conventions}</TableCell> */}
        </TableRow>
      );
      if (folder.childrens.length > 0) {
        rows.push(...renderRows(folder.childrens, depth + 1, uniqueKey));
      }
      return rows;
    });
  };
  const changeTemplateFn = (value) => {
    setImportCrud({})
    setCheckedFolders([])
    if (value?.id) {
      setLoading(true)
      setTimeout(() => { onChangeTemplate(value, 'dropdown') }, 400);
    }
    else { onChangeTemplate(value) }
  }
  const checkForDuplicateArr = (selectedFolder) => {
    let existingFolder = [...keywordsCreated, ...checkedFolders.filter(item => item?.is_checked == true)]
    if (existingFolder.filter(item =>  selectedFolder?.name == item?.name)?.length ){
      return false
    }
    else{
      return true
    }
  }
  return (
    <>
      {isLoading ? <PageLoader /> : null}
      <Dialog
        open={addKeywords?.modal}
        onClose={() => { toggleModalFn(false) }}
        className='console-management-modal'
        maxWidth={false}
      >
        <DialogTitle id="scroll-dialog-title">
          Select Template Folders
        </DialogTitle>
        <DialogContent dividers={true} >
          <div style={{ minHeight: '600px', minWidth: '500px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  style={{ marginBottom: '20px' }}
                  options={templates}
                  getOptionLabel={option => option.name}
                  disableClearable={true}
                  id="template"
                  value={importCrud.template || null}
                  onChange={(e, value) => changeTemplateFn(value)}
                  renderInput={params => <TextField required {...params} label="Select Template" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              {templateCrud?.length ?
                <div style={{ overflow: "scroll", width: `${(40) + (maxObj * 20)}%`, minWidth: "100%" }}>
                  <Table className='mui-table-format'>
                    <TableHead>
                      <TableRow>
                        <TableCell >Main Folders</TableCell>
                        {
                          Array.from({ length: maxObj - 1 }).map((item, index) => (
                            <TableCell >Sub Folder {index + 1} </TableCell>
                          ))
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {templateCrud?.length ?
                        renderRows(templateCrud)
                        : null
                      }
                    </TableBody>
                  </Table>
                </div>
                :
                <Paper style={{ textAlign: 'center', padding: '20px' }}>
                  {isLoading ?
                    <CircularProgress size={24} /> :
                    'Choose template to you wish to select.'
                  }
                </Paper>
              }
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
          <Button disabled={(checkedFolders.filter(item => item?.is_checked == true)?.length == 0) || isLoading} onClick={onSubmitData} color='primary' size='small' variant='contained'>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
