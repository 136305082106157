import React, { useState, } from 'react';
import { Paper, Table, TableBody, TableHead, TableCell, TableSortLabel, TablePagination, TableRow, Checkbox, Tooltip, Button, OutlinedInput } from '@material-ui/core';
import { useTableStyle } from '../../utils/materialStyles';
import InfoIcon from '@material-ui/icons/Info';
import { regexConstants } from '../../constants/regEx';
import { useSnackbar } from 'notistack';
export default function TableListComp({ bulkOperation = false, size = 'medium', bulkFlag, heads, noRecord, data, sort = '', sort_by = '', pagination = {}, jumpToFlag, onChangePage, onChangeRowsPerPage, createSortHandler, classNames, toggleBulkOps, tooltipContent,heightAuto,dataroomPage }) {
	const classes = useTableStyle();
	const [pageNo, setPageNo] = useState();
	const { enqueueSnackbar } = useSnackbar();
	const numberofPage = Math.ceil(pagination?.total / pagination?.per_page)
	const jumpToPage = (value) => {
		if (numberofPage < value) {
			enqueueSnackbar("No Page found", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
		} else {
			if (value < 1) {
				enqueueSnackbar("Please enter value  greater than 1", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
			} else {
				onChangePage(null, value - 1);
			}
		}
	};
	
	return (
		<div style={{ clear: 'both', width: '100%' }}>
			<Paper>
			<div className={classes.tableWrapper} style={dataroomPage ? null :{height:heightAuto? heightAuto:(window.innerHeight-360) + 'px' ,overflow:'auto' ,overflowX:"hidden"}}>
					<Table
						className={classes.table + ' mui-styled-table ' + classNames}
						aria-labelledby="tableTitle"
						stickyHeader={true}
						size={size}
					>
						<TableHead className="mui-table">
							<TableRow>
								{bulkOperation ?
									<TableCell>
										<Checkbox
											size="small"
											checked={bulkFlag}
											onChange={(e) => toggleBulkOps(e.target.checked)}
											name="bulk_operation"
											color="primary"
										/>
									</TableCell> : null
								}
								{heads.map((row, index) =>
									<TableCell
										key={index}
										style={row.style}
										align={row.actionCell ? 'right' : 'left'}
										padding={'default'}
										sortDirection={sort === row.id ? (sort_by ? sort_by : 'asc') : false}
									>

										{row.sortOption && noRecord === null ?
											<TableSortLabel
												active={sort === row.id}
												direction={sort_by && sort === row.id ? sort_by : 'asc'}
												onClick={() => createSortHandler(row.id)}
											>
												{row.label}
											</TableSortLabel> : <span>{row.label}</span>
										}
										{row.tooltip ?
											<Tooltip title={tooltipContent} placement="top" >
												<InfoIcon style={{ fontSize: '16px', verticalAlign: 'sub', color: '#3f51b5' }} />
											</Tooltip> : null
										}
									</TableCell>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{noRecord ? <TableRow><TableCell colSpan={bulkOperation ? heads.length + 1 : heads.length} align="center">{noRecord}</TableCell></TableRow> : data}
						</TableBody>
					</Table>
				</div>
				{pagination && Object.keys(pagination).length && pagination.total > 0 ?
					<div>
						<ul className='pagination-alignment'>
							<li>
								<TablePagination
									showFirstButton={true}
									showLastButton={true}
									rowsPerPageOptions={[10, 20, 50, 100, 200]}
									component="div"
									count={pagination.total}
									rowsPerPage={pagination.per_page}
									page={pagination.page - 1}
									backIconButtonProps={{ 'aria-label': 'previous page' }}
									nextIconButtonProps={{ 'aria-label': 'next page' }}
									onChangePage={onChangePage}
									onChangeRowsPerPage={onChangeRowsPerPage}
									labelRowsPerPage="Rows per page:"
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}  ||   Page  ${pagination.page} of ${numberofPage}`}
								/>
							</li>
							{jumpToFlag &&
								<li style={{ marginTop: '13px', textAlignLast: "center" }}>
									<OutlinedInput
										placeholder='Jump to'
										id="outlined-basic"
										className='pagination-custom-field'
										value={pageNo}
										onChange={(event) => { if (regexConstants.numberUpto5.test(event.target.value)) { setPageNo(event.target.value); } }}
										inputProps={{ style: { fontSize: '13px' } }}
									/>
									<button
										className='go-buttons'
										size="small"
										variant="outlined"
										value={pageNo}
										onClick={(e) => jumpToPage(e.target.value)}
									>Go</button>
								</li>
							}
						</ul>
					</div>
					: null
				}
			</Paper>
		</div>
	)
}
