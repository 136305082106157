import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/core/styles';
import componentPermissionHoc from '../../../hoc/componentPermissionHoc'
import { checkPermission } from '../../../utils';
const MoreActionManu = ({match, history, resourcesKits, downloadResourceKit}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
   setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
   if (anchorRef.current && anchorRef.current.contains(event.target)) {
     return;
   }

   setOpen(false);
  };

  function handleListKeyDown(event) {
   if (event.key === 'Tab') {
     event.preventDefault();
     setOpen(false);
   }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
   if (prevOpen.current === true && open === false) {
     anchorRef.current.focus();
   }

   prevOpen.current = open;
  }, [open]);

  const miniPackRedirection = () => {
    if (history.location.search === "?archived-assets") {
        history.push({
        pathname:`/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/mini-pack/list`,
        search: `${history.location.search}`
      });
    }
    else {
        history.push(`/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/mini-pack/list`)
      }
    }
    
  return(
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant="contained"
        color="primary"
        endIcon={<ArrowDropDownIcon />}
        size="small"
      >
        More
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  { checkPermission('records','data_room','DN') ?
                    <MenuItem onClick={(event) => {handleClose(event); downloadResourceKit({download:'asset_folder_empty_str'})}}>
                      Empty Folder Structure
                      <CloudDownloadIcon fontSize="small" style={{marginLeft:'auto', paddingLeft:'6px'}} className="icon-file" color="primary" />
                    </MenuItem>:null
                  }
                  { checkPermission('records','data_room','DN') ?
                    <MenuItem onClick={(event) => {handleClose(event); downloadResourceKit({download:'data_room_tpl'})}}>
                      Data Room Template
                      <CloudDownloadIcon fontSize="small" style={{marginLeft:'auto', paddingLeft:'6px'}} className="icon-file" color="primary" />
                    </MenuItem>:null
                  }
                  
                  { checkPermission('records','category','R') ?
                      resourcesKits.map((kit, index) =>
                        <MenuItem
                          onClick={() => history.location.search === "?archived-assets" ? history.push({
                            pathname: `/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/kit/${kit.name == 'Marketing Kit' ? 'marketing_kit' : 'engineering_kit'}/${kit.uuid}/`,
                            search: `${history.location.search}`
                          }) : history.push(`/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/kit/${kit.name == 'Marketing Kit' ? 'marketing_kit' : 'engineering_kit'}/${kit.uuid}/`)}
                        >
                        {kit.name}
                        <ArrowForwardIosIcon fontSize="small" style={{marginLeft:'auto', paddingLeft:'6px'}} className="icon-file" color="primary" />
                      </MenuItem>
                    ):null
                  }
                  {/* {checkPermission('records', 'mini-pack', 'R') ?
                    <MenuItem onClick={miniPackRedirection} >
                      MiniPack
                      <ArrowForwardIosIcon fontSize="small" style={{ marginLeft: 'auto', paddingLeft: '6px' }} className="icon-file" color="primary" />
                    </MenuItem>
                    : null
                  } */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
export default withRouter(MoreActionManu);
