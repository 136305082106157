import { globalPostService, globalGetService, globalOpenPostService } from '../../utils/globalApiServices';
import { checkApiStatus, clearCookie, eraseGlobalCookie, setGlobalCookie, checkEnvironment, getGlobalCookie, getLocalStorageInfo } from '../../utils';
import { trackLogin, trackPublicActivity } from '../../utils/mixpanel'
import Cookies from 'universal-cookie'
const cookies = new Cookies();
export function loginApi(props, data = {}) {
  this.setState({ formSubmitLoader: true });
  globalPostService('api/login/', data)
    .then(response => {
      this.setState({ formSubmitLoader: false });
      if (checkApiStatus(response)) {
        if (response.data.data.lessor_list.length) {
          let baseDomain = '.sparta.aero'
          let expireAfter = new Date();
          expireAfter.setDate(expireAfter.getDate() + 7);
          let cookieData = JSON.stringify({ access: response.data.data.access })
          setGlobalCookie("lessorAccess", cookieData, 3)
          setGlobalCookie("domain", baseDomain, 3)
          localStorage.setItem('userInfo', JSON.stringify(response.data.data));
          if (response.data.data.lessor_list.length > 1) {
            if(getLocalStorageInfo() && getGlobalCookie('lessorAccess')){
              props.history.push('/select-lessor');
            }
          } else {
            this.lessorUpdateApi(props, { lessor_id: response.data.data.lessor_list[0].id })
          }
        } else {
        }
      } else if (response.data.statusCode == 1012) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        props.history.push('/otp-verification/' + response.data.data.token)
        localStorage.setItem('userEmail', data.email)
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}

export function lessorUpdateApi(props, data = {}) {
  this.setState({ formSubmitLoader: true });
  globalPostService(`api/user-authorization/`, data)
    .then(response => {
      this.setState({ formSubmitLoader: false });
      if (checkApiStatus(response)) {
        if (response.data.data.user.permission.records) {
          this.props.enqueueSnackbar('Login Successful.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          let baseDomain = '.sparta.aero'
          let expireAfter = new Date();
          expireAfter.setDate(expireAfter.getDate() + 7);
          let cookieData = JSON.stringify({ access: response.data.data.lessor_access_token, id: response.data.data.current_lessor.id, refresh: '', environment: checkEnvironment() })
          let cookieName = JSON.stringify({ name: response.data.data.user.name, designation: response.data.data.user.designation })
          setGlobalCookie('userName', cookieName, 3)
          setGlobalCookie("lessorAccess", cookieData, 3)
          setGlobalCookie("domain", baseDomain, 3)
          let userInfo = JSON.parse(localStorage.getItem('userInfo'));
          userInfo = {
            ...userInfo,
            access: response.data.data.lessor_access_token,
            defaultLessor: response.data.data.current_lessor,
            user: response.data.data.user
          }
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          let redirectURIRecords = getGlobalCookie('redirectURIRecords')
          if (redirectURIRecords !== undefined && redirectURIRecords !== null && redirectURIRecords !== '' && redirectURIRecords !== '/' && !redirectURIRecords.includes('login') && !redirectURIRecords.includes('select-lessor') && redirectURIRecords.includes('records.sparta') && !redirectURIRecords.includes('signup') && !redirectURIRecords.includes('termscondition') && !redirectURIRecords.includes('data-policy')) {
            window.location.assign(redirectURIRecords)
          } else {
            props.history.push('/assets');
            if (props.match.path === '/assets') {
              window.location.reload();
            }
          }
          setTimeout(() => trackLogin(userInfo, () => {
            window.location.reload()
          }), 500)
        } else {
          let pageUrl = props.match.path;
          if (pageUrl.includes('/login') || pageUrl.includes('/select-lessor')) {
            this.props.enqueueSnackbar('You donot have permission on records, please contact to support@acumen.aero', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            localStorage.clear();
            clearCookie('lessorAccess', '.sparta.aero', '/')
            eraseGlobalCookie('lessorAccess')
            eraseGlobalCookie('domain')
            cookies.remove('lessorAccess', { path: "/", domain: "sparta.aero" })
            this.props.history.push('/login')
          } else {
            this.props.enqueueSnackbar('You donot have permission on records for this instance', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        }
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        localStorage.clear();
        clearCookie('lessorAccess', '.sparta.aero', '/')
        eraseGlobalCookie('lessorAccess')
        eraseGlobalCookie('domain')
        cookies.remove('lessorAccess', { path: "/", domain: "sparta.aero" })
        this.props.history.push('/login')
      }
    })
}
// State Update Related Functions
export function updateAuthInfoState(value, key) {
  this.setState(prevState => ({
    ...prevState,
    authInfo: {
      ...prevState.authInfo,
      [key]: value
    },
    error: {
      ...prevState.error,
      [key]: ''
    }
  }))
}
export function filePreviewDetailApi(props, uuid, updateType='',parentUrl) {
  globalPostService(`records/v2/get-file-property/`, { uuid: uuid, type: 'file',"with_tags":true, trash: window.location.href.includes('trash') ? true : null, mininpack_files:parentUrl?.includes('mini-pack-config')? true: null  })
    .then(response => {
      if (checkApiStatus(response)) {
        if (Object.keys(response.data.data).length) {
          if(updateType){
            this.setState(prevState => ({
              ...prevState,
              fileInfo:{
                ...prevState.fileInfo,
                issues_count: response.data.data.issues_count
              }
            }))
          }else{
            this.setState({ fileInfo: response.data.data });
          }
        } else {
          this.props.enqueueSnackbar('File detail not found', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      }
    })
}
export function getSharedContentApi(props, shareKey, queryParams, loaderType) {
  this.setState({ [loaderType]: true });
  globalGetService(`records/v2/view-shared-records/${shareKey}/`, queryParams)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        this.setState({ sharedData: response.data.data })
      }
    })
}
export function downloadShareDataApi(props, shareKey, emails) {
  this.setState({ pageLoader: true });
  let url = `records/v2/share-record/folder/${shareKey}/zip-download-user/`
  if (shareKey.includes('*$$*xx')) {
    url = `records/v2/share-record/file/${shareKey}/zip-download-user/`
  }
  globalPostService(url, { user_email: [emails] })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        trackPublicActivity('Shared Data Downloaded', { page_title: 'Shared Data', asset_id: response.data.data.asset_id, type: 'Public', email: emails, share_key: shareKey, url: url })
        this.setState({ downloadShareData: { modal: false, email: '' } })
        globalOpenPostService(`records/v2/share/activity/`, { user_id: null, share_link: true, activity_type: shareKey.includes('*$$*xx') ? 33 : 34, details: shareKey })
          .then(response => {
            if (checkApiStatus(response)) {

            }
          })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function shareLinkActivityApi(props, shareKey, activityId) {
  globalOpenPostService(`records/v2/share/activity/`, { user_id: null, share_link: true, activity_type: activityId, details: shareKey })
    .then(response => {
      if (checkApiStatus(response)) {

      }
    })
}

export function trackFileDownloadActivity(data, key) {
  let url = `/records/file_download_activity_update/${key}/?device=web`
  if(window.location.href.includes('shared')){
    url = `records/v2/share/activity/`
    globalPostService(url ,{
      "user_id": null,
      "share_link": true,
      "activity_type": 33,
      "details": key
    })
      .then(response => {
        trackPublicActivity('Shared Data Downloaded', { page_title: 'Shared', event_type: 'Preview', page_type: 'Public', share_key: key, extension: data.extension, item_name: data.name, item_size: data.size })
      })
  } else {
    globalGetService(url)
      .then(response => {
        trackPublicActivity('Shared Data Downloaded', { page_title: 'Shared', event_type: 'Preview', share_key: key })
      })
  }
}
export function OTPVerifyApi(props = {}, data) {
  globalPostService('api/pass-verify-otp/', data)
    .then(response => {
      if (checkApiStatus(response)) {
        localStorage.clear()
        props.history.push('/login');
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
      else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function OTPResendApi(props = {}, data) {
  globalPostService('api/pass-resend-otp/', data)
    .then(response => {
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
      else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
