import { getLocalStorageInfo } from "../../../utils";

/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
=======
*/
//for sparta.aero (girishkvg44@gmail.com)
//const ClientID = '7b13410c5d204bc482519783fabcd648'
//for local.sparta.aero (girishkvg44@gmail.com)
const ClientID = '9ab46418f15a476eb2176e30aeddefe6'
//for localhost
//const ClientID = 'e7a0aef0b6c742378e8af2d4d1d0afb0'

const profileName = getLocalStorageInfo()?.user?.name||''; 
const profileId = getLocalStorageInfo()?.user?.slug||'';
const profile = {
        'userProfile': {
          'name': profileName,
          'firstName': profileName.substring(0, profileName.indexOf(' ')) ,
          'lastName': profileName.substring(profileName.indexOf(' ') + 1),
          'email': profileId
        }};
class ViewSDKClient {
    constructor() {
        this.readyPromise = new Promise((resolve) => {
            if (window.AdobeDC) {
                resolve();
            } else {
                /* Wait for Adobe Acrobat Services PDF Embed API to be ready */
                document.addEventListener("adobe_dc_view_sdk.ready", () => {
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
    }

    ready() {
        return this.readyPromise;
    }

    previewFile(divId, viewerConfig) {
        const config = {
            /* Pass your registered client id */
            clientId: ClientID,
        };
        if (divId) { /* Optional only for Light Box embed mode */
            /* Pass the div id in which PDF should be rendered */
            config.divId = divId;
        }
        /* Initialize the AdobeDC View object */
        this.adobeDCView = new window.AdobeDC.View(config);

        /* Invoke the file preview API on Adobe DC View object */
        const previewFilePromise = this.adobeDCView.previewFile({
            /* Pass information on how to access the file */
            content: {
                /* Location of file where it is hosted */
                location: {
                    //"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                    url: decodeURIComponent(viewerConfig.path),
                    /*
                    If the file URL requires some additional headers, then it can be passed as follows:-
                    headers: [
                        {
                            key: "<HEADER_KEY>",
                            value: "<HEADER_VALUE>",
                        }
                    ]
                    */
                },
            },
            /* Pass meta data of file */
            metaData: {
                /* file name */
                fileName: viewerConfig.name,
                /* file ID */
                id: viewerConfig.uuid,
                hasReadOnlyAccess:false
            }
        }, viewerConfig);

        return previewFilePromise;
    }



    previewFileUsingFilePromise(divId, filePromise, fileName) {
        /* Initialize the AdobeDC View object */
        this.adobeDCView = new window.AdobeDC.View({
            /* Pass your registered client id */
            clientId: ClientID,
            /* Pass the div id in which PDF should be rendered */
            divId,
        });

        /* Invoke the file preview API on Adobe DC View object */
        this.adobeDCView.previewFile({
            /* Pass information on how to access the file */
            content: {
                /* pass file promise which resolve to arrayBuffer */
                promise: filePromise,
            },
            /* Pass meta data of file */
            metaData: {
                /* file name */
                fileName: fileName
            }
        }, {});

    }

    registerSaveApiHandler() {
        /* Define Save API Handler */
        const saveApiHandler = (metaData, content, options) => {
            return new Promise(resolve => {
                /* Dummy implementation of Save API, replace with your business logic */
                setTimeout(() => {
                    const response = {
                        code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                        data: {
                            metaData: Object.assign(metaData, {updatedAt: new Date().getTime()})
                        },
                    };
                    resolve(response);
                }, 2000);
            });
        };

        this.adobeDCView.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.SAVE_API,
            saveApiHandler,
            {}
        );
    }

    registerEventsHandler() {
        this.adobeDCView.registerCallback(
            /* Type of call back */
            window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
            /* call back function */
            function() {
              return new Promise((resolve, reject) => {
                 resolve({
                    code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                    data: profile
                 });
              });
            },
            {}
        );

    }
}

export default ViewSDKClient;
