import React, { Fragment } from 'react';
import { withRouter} from 'react-router-dom'
import { Grid, Button, Tooltip, CircularProgress } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FolderLevelFilesModal from './FolderLevelFilesModal';
import componentPermissionHoc from '../../../hoc/componentPermissionHoc'
import { checkPermission } from '../../../utils';
import { Check } from '@material-ui/icons';
import { onCheckActionPermission } from '..';
const DataRoomNav = ({match, selectedItems, onDeleteDMData, onBulkDMMove, toggleModalFn, toggleUploadModalFn, dataRoom, analyticStats, shareRecord, onDownloadData, changeDMFilesStatus,setselectedAsset, onSendViaEmail,onDownloadData_v2,onDownloadDataV2, showDownloadv2,applyTemplate}) => {


  return(
    <Fragment>
      <div className="data-room-nav">
        <Grid container alignItems="center" spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} md={12}>
            <ul className="list-inline flex-centered data-room-upload-option">
              { checkPermission('records','data_room', 'DN') || (dataRoom.folder_collaboration && (dataRoom.folder_collaboration.can_edit === 1 || dataRoom.folder_collaboration.can_edit === 0)) ?
                <li className="list-inline-item">
                  <Button onClick={() => onDownloadData('single')} color="primary" size="small" variant="contained" endIcon={<CloudDownloadIcon />}>Download</Button>
                  <Button disabled={showDownloadv2 == 1} onClick={() => onDownloadDataV2('single')} color="primary" size="small" variant="contained" endIcon={<CloudDownloadIcon />}>
                    {showDownloadv2 == 1 ? <><CircularProgress size={24}/>Downloading..</>: 'Download v2'}
                    </Button>
                </li>
                :null
              }
              { match.params.type === 'technical' && checkPermission('records','data_room', 'PUB_REJ') && analyticStats.general_info && analyticStats.general_info.unpublished_files && onCheckActionPermission(dataRoom) ?
              <>
                <li className="list-inline-item">
                  <FolderLevelFilesModal changeDMFilesStatus={changeDMFilesStatus} analyticStats={analyticStats} type="unpublished" />
                </li>
                <li>
                  <Button onClick={() => {changeDMFilesStatus({status:3,type:'file', uuid:[], to_consider:'unpublished', folder_uuid:[match.params.uuid]})}} size="small" variant="contained"  color="primary">Publish All - {analyticStats.general_info.unpublished_files}</Button>
                </li>
              </>:null
              }
              { match.params.type === 'technical' && checkPermission('records','data_room', 'RENAME') && analyticStats.general_info && analyticStats.general_info.to_be_renamed ?
                <li className="list-inline-item">
                  <FolderLevelFilesModal changeDMFilesStatus={changeDMFilesStatus} analyticStats={analyticStats} type="rename" />
                </li>:null
              }
              { selectedItems.ids.length ?
                 <Fragment>
                  { checkPermission('records','data_room', 'DN') || (dataRoom.folder_collaboration && (dataRoom.folder_collaboration.can_edit === 1 || dataRoom.folder_collaboration.can_edit === 0)) ?
                    <li className="list-inline-item">
                      <Button onClick={() => onDownloadData('multi')} color="primary" size="small" variant="outlined">Download test({selectedItems.ids.length})</Button>
                    </li>:null
                  }
                  { checkPermission('records','data_room', 'MOV') && onCheckActionPermission(dataRoom)?
                    <li className="list-inline-item">
                      <Button onClick={() => {setselectedAsset(); setTimeout(() => {onBulkDMMove()}, 300)}}  color="primary" size="small" variant="outlined">Move ({selectedItems.ids.length})</Button>
                    </li>
                    :null
                  }
                  { onCheckActionPermission(dataRoom)?
                    <li className="list-inline-item">
                      <Button onClick={() => {setselectedAsset(); setTimeout(() => {onBulkDMMove('copy')}, 300)}}  color="primary" size="small" variant="outlined">Copy ({selectedItems.ids.length})</Button>
                    </li>
                    :null
                  }
                  { checkPermission('records','data_room', 'D') && onCheckActionPermission(dataRoom) ?
                    <li className="list-inline-item">
                      <Button onClick={() => onDeleteDMData()} color="primary" size="small" variant="outlined">Trash ({selectedItems.ids.length})</Button>
                    </li>:null
                  }
                    { checkPermission('records','data_room', 'SNDEML') ?
                    <li className="list-inline-item">
                      <Button onClick={() => onSendViaEmail(selectedItems)} color="primary" size="small" variant="outlined">Send via Email ({selectedItems.ids.length})</Button>
                    </li>:
                    null}
                  {checkPermission('records', 'data_room', 'DN') || (dataRoom.folder_collaboration && (dataRoom.folder_collaboration.can_edit === 1 || dataRoom.folder_collaboration.can_edit === 0)) ?
                    <li className="list-inline-item">
                      <Button onClick={() => onDownloadData_v2('multi')} color="primary" size="small" variant="outlined">Download V2({selectedItems.ids.length})</Button>
                    </li> : null
                  }

                 </Fragment>:null
              }
              {/* { dataRoom?.list?.length ? null :
                 <li>
                 <Button onClick={() => {applyTemplate(dataRoom?.navigation)}} size="small" variant="contained"  color="primary">Apply Template</Button>
               </li>
              } */}
            </ul>
          </Grid>
        </Grid>
        {showDownloadv2 ? 
        <div className="file-upload-stats" style={{ height: '42px', paddingRight:'20px'}}>
            <h4 className="flex-centered" style={{ width:'max-content' }}>
           { showDownloadv2 ==1? <CircularProgress size={20} style={{ marginRight: '5px' }} />: null}
              <span>{ showDownloadv2 ==1? `Download is in progress...`:<div style={{padding:'5px', display:"flex"}}><Check  style={{ marginRight: '5px', color:'green' }}/> You will receive Email with downloadable link</div>}</span>
            </h4>
      </div>: null}
      </div>
    </Fragment>
  )
}
export default withRouter(DataRoomNav);
