import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import config from '../../config';
import homeIcon from '../../assets/images/home_icon.png';
import dbIcon from '../../assets/images/dashbordIcon.svg';
import reviewDockIcon from '../../assets/images/review_dock.png';
import trashIcon from '../../assets/images/trash_icon.png';
import reportIcon from '../../assets/images/report_icon.png';
import settingIcon from '../../assets/images/setting.svg';
import templatesIcon from '../../assets/images/template.svg';
import { checkPermission } from '../../utils'
export const MainNavLink = ({ location, match }) => {
  const getReviewDockUrl = () => {
    let actionDockurl = '';
    if (checkPermission('records','data_room','R')) {
      if(checkPermission('reviewdock','inqueue','R')) {
        actionDockurl = '/action-dock/in_queue';
      } else if (checkPermission('reviewdock','to_be_renamed','R')) {
        actionDockurl = '/action-dock/rename_queue';
      } else if (checkPermission('reviewdock','ocr_inqueue','R')) {
        actionDockurl = '/action-dock/ocr_queue';
      } else if (checkPermission('reviewdock','rejected','R')) {
        actionDockurl = '/action-dock/rejected_queue';
      }else if(checkPermission('reviewdock','ocr_rejected','R')){
        actionDockurl = '/action-dock/ocr_rejected_queue';
      }
    }
    return actionDockurl;
  }
  return (
    <Fragment>
      <ul className="list-unstyled" style={{ marginTop: '20px', marginBottom:'75px' }}>
        <li>
          <a href={config.domain.subDomian} target="_blank">
            <img src={homeIcon} alt="Home" />
            <p>Home</p>
          </a>
        </li>
        {checkPermission('records','dashboard','R') ?
          <li>
            <Link className={match.path.includes('dashboard') ? 'active' : ''} to='/dashboard'>
              <img src={dbIcon} alt="Dashboard" />
              <p>Dashboard</p>
            </Link>
          </li>:null
        }
        <li>
          <Link className={ !match.path.includes('archived-assets') && !location.search.includes('archived-assets') && ( match.path.includes('assets') || match.path.includes('data-room')) ? 'active' : ''} to='/assets'>
            <img src={`${config.api.s3ImageUrl}asset_list_icon.png`} alt="Assets" />
            <p>Assets</p>
          </Link>
        </li>
        { checkPermission('reviewdock','inqueue','R') || checkPermission('reviewdock','to_be_renamed','R') || checkPermission('reviewdock','ocr_inqueue','R') || checkPermission('reviewdock','ocr_rejected','R') || checkPermission('reviewdock','rejected','R') ?
          <li>
            <Link className={match.path.includes('action-dock') ? 'active' : ''} to={getReviewDockUrl()}>
              <img src={reviewDockIcon} alt="Review Dock" />
              <p>Review Dock</p>
            </Link>
          </li> : null
        }
        { checkPermission('trash','trash','R') ?
          <li>
            <Link className={match.path.includes('trash') ? 'active' : ''} to='/trash'>
              <img src={trashIcon} alt="Trash" />
              <p>Trash</p>
            </Link>
          </li> : null
        }
        { checkPermission('reports','activities','R') || checkPermission('reports','inventory','R') || checkPermission('reports', 'custom_alert', 'R') ?
          <li>
            <Link className={match.path.includes('reports') ? 'active' : ''} to={checkPermission('reports','activities','R') ? '/reports/activities': checkPermission('reports','inventory','R') ?  '/reports/inventory' : '/reports/customalerts'}>
              <img src={reportIcon} alt="Report" />
              <p>Reports</p>
            </Link>
          </li> : null
        }
        { checkPermission('templates','templates','R')?
            <li>
              <Link className={ (match.path.includes('templates') && !match.path.includes('/settings/templates')) ? 'active' : ''} to='/templates'>
                <img src={templatesIcon} alt="Templates" />
                <p>Templates</p>
              </Link>
            </li>
            : null
        }
        { checkPermission('settings','enable_auto-publish_file(s)','R') || checkPermission('settings','records_template','R') || checkPermission('records','tags','R') || checkPermission('settings', 'config_mini_pack', 'R') ?
          <li>
            <Link className={match.path.includes('settings') ? 'active':''} to={ (checkPermission('settings','enable_auto-publish_file(s)','R') || checkPermission('settings','records_template','R')) ? '/settings/templates':'/settings/tags'}>
              <img src={settingIcon} alt="Settings" />
              <p>Settings</p>
            </Link>
          </li>:null
      }
      { checkPermission('technical_specs','archived_assets','R') ?
        <li>
          <Link className={match.path.includes('archived-assets') || location.search.includes('archived-assets') ? 'active' : ''} to='/archived-assets'>
            <img src={`${config.api.s3ImageUrl}asset_list_icon.png`} alt="Assets" />
            <p>Archived Assets</p>
          </Link>
        </li>:null
      }
      </ul>
    </Fragment>
  )
}
export default withRouter(MainNavLink);
