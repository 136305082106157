import React, { useState } from 'react'
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core'
export default function ReadMorePopup({ data, title = 'Expanded View' }) {
    const [readmore, setReadmore] = useState({});
    const toggleModal = () => {
        setReadmore({})
    }
    return (
        <div className='read-more-pop-up'>
            {
                data?.length ?
                    data?.length <= 3 ?
                        data.map(item => <span>
                            <Tooltip placement='top' arrow title={item.name}>
                                 <Chip
                                    style={{ maxWidth: '120px' }}
                                    className='chp-cls'
                                    label={item.name}
                                    color={'primary'}
                                    variant='outlined'
                                />
                            </Tooltip>
                        </span>)
                        : <>{

                            data.filter((item, index) => index < 3).map(item =>
                                <span>
                                    <Tooltip placement='top' arrow title={item.name}>
                                            <Chip
                                                style={{ maxWidth: '120px' }}
                                                className='chp-cls'
                                                label={item.name}
                                                color={'primary'}
                                                variant='outlined'
                                            />
                                    </Tooltip>
                                </span>
                            )}  {<Chip
                                className='chp-cls'
                                label={'...more'}
                                color={'primary'}
                                onClick={() => setReadmore({ data: data, modal: true })}
                            />}</>
                    : '--'
            }

            <Dialog
                open={readmore?.modal}
                onClose={() => { toggleModal() }}
                aria-labelledby="scroll-dialog-title"
                className='records-dataroom-modal read-more-pop-up'
            >
                <DialogTitle> {title} </DialogTitle>
                <DialogContent dividers={true} >
                    {readmore?.data?.map(item =>
                        <Chip
                            style={{ marginBottom: '12px' }}
                            className='chp-cls'
                            label={item.name}
                            color={'primary'}
                            variant='outlined'
                        />


                    )
                    }
                </DialogContent>
                <DialogActions>
                    <Button size='small' onClick={() => setReadmore({})} variant="contained" color="primary">Okay</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
