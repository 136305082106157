import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Grid, Checkbox, FormControlLabel, Paper, Menu, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { ExportManu } from '../../../shared_elements';
import { checkPermission, getLocalStorageInfo } from '../../../utils';
import activeGraphIcon from '../../../assets/images/active-pie-chart.svg'
import inactiveGraphIcon from '../../../assets/images/inactive-pie-chart.svg'
import { permissionKeys } from '../'
const DockBulkOps = ({match, ocrAnalyticFlag, filter, bulkOperation,toggleBulkOps, selectAllSearch, actionDocks, onChangeBulkOps, selectOnSearch, exportReportFn, onChangeOCRAnalytic}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return(
    <div className="review-docks-bulk-ops">
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={2} sm={12} md={4}>
          <ul className='list-inline flex-centered'>
            <li className='list-inline-item' style={{borderRight:'1px solid #d7d7d7', paddingRight:'10px', cursor:'pointer', marginRight:'10px'}}>
              <CloseIcon onClick={() => toggleBulkOps(false)} fontSize='small' color='primary' />
            </li>
            <li className='list-inline-item'>
              { !ocrAnalyticFlag && (checkPermission('reviewdock',permissionKeys[match.params.type],'PUB_REJ') || checkPermission('reviewdock',permissionKeys[match.params.type],'D') || checkPermission('reviewdock',permissionKeys[match.params.type],'RSTOR')) ?
                <Fragment>
                  {filter && Object.keys(filter).length && (actionDocks.pagination.total > actionDocks.pagination.per_page) && bulkOperation.ids.length ?
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="bulk-sel"
                          color="primary"
                          className="checkbox-icon"
                          size="small"
                          onChange={(e) => selectOnSearch(e.target.checked)}
                        />
                      }
                      label={<p style={{inlineSize:'max-content',fontSize:'0.8rem'}} className='checklist-label-width'>{`Select all files that match this search (${actionDocks.pagination.total})`}</p>}
                    />:null
                  }
                </Fragment>:null
              }
            </li>
          </ul>
          
        </Grid>
        <Grid item xs={10} sm={12} md={8}>
          <ul className="list-inline dock-bulk-ops-list">
            {!ocrAnalyticFlag ?
              <Fragment>
                { bulkOperation.ids.length && match.params.type == 'rejected_queue' && checkPermission('reviewdock',permissionKeys[match.params.type],'RSTOR') ?
                  <li className="list-inline-item">
                    <Button onClick={() => onChangeBulkOps('Restore')} size="small" variant="outlined" color="primary">Restore ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</Button>
                  </li> : null
                }
                { bulkOperation.ids.length && checkPermission('reviewdock',permissionKeys[match.params.type],'D') ?
                  <li className="list-inline-item">
                    <Button onClick={() => onChangeBulkOps('Trash')} size="small" variant="outlined" color="secondary">Trash ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</Button>
                  </li> : null
                }
                {match.params.type === 'in_queue' || match.params.type === 'rename_queue' ?
                  <Fragment>
                    { bulkOperation.ids.length && checkPermission('reviewdock',permissionKeys[match.params.type],'PUB_REJ') ?
                      <Fragment>
                          <li className="list-inline-item">
                            <Button onClick={() => onChangeBulkOps('Reject')} size="small" variant="outlined" color="secondary">Reject ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</Button>
                          </li>
                          <li className="list-inline-item">
                            <Button onClick={() => onChangeBulkOps('Publish')} size="small" variant="outlined" color="primary">Publish ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</Button>
                          </li>
                      </Fragment>:null
                    }
                  </Fragment> : null
                }
                { checkPermission('reviewdock',permissionKeys[match.params.type],'EXP') ?
                  <li className="list-inline-item">
                    <ExportManu
                      title="Export"
                      files={[{title:'Excel', extension: 'xlsx', key:''}]}
                      exportReportFn={(file) => exportReportFn(file)}
                    />
                  </li>:null
                }
              </Fragment>:null
            }
            { match.params.type === 'ocr_queue' && checkPermission('reviewdock','ocr_inqueue','ANA') ?
              <Fragment>
                <li className="list-inline-item">
                  <span className="action-dock-switcher">
                    <span onClick={onChangeOCRAnalytic} className={!ocrAnalyticFlag ? 'active' : ''}>
                      <ListAltIcon color={!ocrAnalyticFlag ? 'primary' : 'default'} />
                    </span>
                    <span onClick={onChangeOCRAnalytic} className={ocrAnalyticFlag ? 'active' : ''}>
                      {ocrAnalyticFlag ? <img src={activeGraphIcon} alt="graph" /> : <img src={inactiveGraphIcon} alt="graph" />}
                    </span>
                  </span>
                </li>
              </Fragment> : null
            }
          
          </ul>
          { !ocrAnalyticFlag ?
              <div className='mobile-menu'>
                <Button size="small" variant="outlined" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                  Actions
                </Button>
                <Menu
                  id="bulk-ops-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  { bulkOperation.ids.length && match.params.type == 'rejected_queue' && checkPermission('reviewdock',permissionKeys[match.params.type],'RSTOR') ?
                    <MenuItem onClick={() => onChangeBulkOps('Restore')}>Restore ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</MenuItem> : null}
                  {
                    bulkOperation.ids.length && checkPermission('reviewdock',permissionKeys[match.params.type],'D') ?
                    <MenuItem onClick={() => onChangeBulkOps('Trash')}>Trash ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</MenuItem> : null
                  }
                  { match.params.type === 'in_queue' || match.params.type === 'rename_queue' ?
                    <Fragment>
                    { bulkOperation.ids.length && checkPermission('reviewdock',permissionKeys[match.params.type],'PUB_REJ') ?
                      <Fragment>
                        <MenuItem onClick={() => onChangeBulkOps('Reject')}>Reject ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</MenuItem>
                        <MenuItem onClick={() => onChangeBulkOps('Publish')}>Publish ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</MenuItem>
                      </Fragment> : null }
                    </Fragment> : null
                  }
                </Menu>
              </div> : null
            }
        </Grid>
      </Grid>
    </div>
  )
}
export default withRouter(DockBulkOps);
