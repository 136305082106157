import userIcon from '../../assets/images/user.svg'
import girlIcon from '../../assets/images/girlIcon.svg'
export const rolesHd = [
  { label: 'Role Type', sortOption: false },
  { label: 'User(s)', sortOption: false },
  { label: 'Action', sortOption: false, actionCell: true }
];
export const usersHd = [
  { label: 'Name', sortOption: false },
  { label: 'Email', sortOption: false },
  { label: 'Designation', sortOption: false },
];
export const permissionHd = [
  {label:'', sortOption:false},
  {label:'View', sortOption:false},
  {label:'Publish', sortOption:false},
  {label:'Reject', sortOption:false},
  {label:'Restore', sortOption:false},
  {label:'Rename', sortOption:false},
  {label:'Trash', sortOption:false},
  {label:'Download', sortOption:false},
  {label:'Upload', sortOption:false},
  {label:'Share', sortOption:false},
  {label:'Export', sortOption:false},
  {label:'More', sortOption:false},
]
export const users = [
  {name:'Alok Anand', email:'alok.anand@acumen.aero', lastLogin:'04-07-2021', designation:'Lessor Administrator', active:true, profile_pic:userIcon},
  {name:'Ameya Gore', email:'ameya.gore@acumen.aero', lastLogin:'04-07-2021', designation:'Lessor Administrator', active:true, profile_pic:userIcon},
  {name:'Vijay Kumar', email:'vijay.kumar@acumen.aero', lastLogin:'04-07-2021', designation:'Records Administrator', active:true, profile_pic:userIcon},
  {name:'Anubhav Chaturvedi', email:'anubhav.chaturvedi@acumen.aero', lastLogin:'04-07-2021', designation:'Records Manager', active:true, profile_pic:userIcon},
  {name:'Aravind Shekar', email:'aravind.shekar@acumen.aero', lastLogin:'04-07-2021', designation:'Records Manager', active:true, profile_pic:userIcon},
  {name:'Neha Gore', email:'neha.gore@acumen.aero', lastLogin:'04-07-2021', designation:'Records Manager', active:true, profile_pic:girlIcon},
  {name:'Sayanee Das', email:'sayanee.das@acumen.aero', lastLogin:'04-07-2021', designation:'Records Inspector', active:false, profile_pic:girlIcon},
  {name:'Divya Shukla', email:'divya.shukla@acumen.aero', lastLogin:'04-07-2021', designation:'Records Inspector', active:true, profile_pic:girlIcon},
  {name:'Babu Padalingam', email:'babu.padalingam@acumen.aero', lastLogin:'04-07-2021', designation:'Records Inspector', active:true, profile_pic:userIcon},
  {name:'Mahesh Prajapat', email:'mahesh.prajapat@acumen.aero', lastLogin:'04-07-2021', designation:'Records Inspector', active:true, profile_pic:userIcon},
  {name:'Nkhil Srivastava', email:'nikhil.srivastava@acumen.aero', lastLogin:'04-07-2021', designation:'Records View (Published & Unpublished)', active:true, profile_pic:userIcon},
  {name:'Rahul Singh', email:'rahul.singh@acumen.aero', lastLogin:'04-07-2021', designation:'Records View (Published Files Only)', active:true, profile_pic:userIcon},
  {name:'Sharmila Sundarraj', email:'sharmila.sundarraj@acumen.aero', lastLogin:'04-07-2021', designation:'Records Custom Access (with Download)', active:true, profile_pic:girlIcon},
  {name:'Vivek Dayashankar', email:'vivek.dayashankar@acumen.aero', lastLogin:'04-07-2021', designation:'Records Custom Access (without Download)', active:true, profile_pic:userIcon},
  {name:'Shabeeb K', email:'shabeeb.kaipanthody@acumen.aero', lastLogin:'04-07-2021', designation:'Records Custom Access (without Download)', active:true, profile_pic:userIcon},
  {name:'Tejas Christopher', email:'tejas.christopher@acumen.aero', lastLogin:'04-07-2021', designation:'Records Custom Access (without Download)', active:true, profile_pic:userIcon},
]

export const templateFilterOptions ={
  name_or_descp: {
    inputType: 'text',
    placeholder: 'Enter name or Description',
    title: 'Name / Description',
  },
  asset_type: {
    inputType: 'dropdown',
    placeholder: 'Select Asset Type',
    title: 'Asset Type',
    options: [{  label:'Aircraft', value:1},{ label:'Engine', value:2},{  label:'APU', value:3},{  label:'Landing Gear', value:4},{ label: 'Propeller', value: 5 }, {label:'Engine Modules', value:10}],
    labelKey: 'label',
    valueKey: 'value'
  },
  aircraft_types: {
    inputType: 'dropdown',
    placeholder: 'Select Aircraft Type.',
    title: 'Aircraft Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
  },
  engine_types: {
    inputType: 'dropdown',
    placeholder: 'Select Engine Type.',
    title: 'Engine Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
  },
  apu_types: {
    inputType: 'dropdown',
    placeholder: 'Select APU Type.',
    title: 'APU Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
  },
}
export const configFilterOptions ={
  name:{
    inputType: 'text',
    placeholder: 'Enter Config Title or Description',
    title: 'Mini-Pack Name / Description',
  },
  status:{
    inputType:'dropdown',
    placeholder:'Select Mini-pack status',
    title:"Status",
    options:[{ label: 'Active', value: 1 }, { label: 'Inactive', value: 2 }],
    labelKey: 'label',
    valueKey: 'value',

  }
}
export const sectionFilterOptions ={
  name:{
    inputType: 'text',
    placeholder: 'Enter Section Name or Description',
    title: 'Section Name / Description',
  },
  
  keywords:{
    inputType: 'text',
    placeholder: 'Enter Section Keywords',
    title: 'Keywords',
  }, 
}
