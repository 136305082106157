import { ArrowBack } from '@material-ui/icons'
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices'
import { checkApiStatus, checkPermission } from '../../../utils'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { AutocompleteCreatable, DeletePopUp, EmptyCollection, FilterComponent, PageLoader, Pagination } from '../../../shared_elements'
import { STableLoader } from '../../../shared_elements/loaders';
import { useSnackbar } from 'notistack';
import ReadMoreInline from '../../../shared_elements/components/ReadMoreInline'
import SectionFolderSelection from './SectionFolderSelection'
import { sectionFilterOptions } from '..'
import ReadMorePopup from '../../../shared_elements/components/ReadMorePopup'
import moment from 'moment'
import { fieldDateFormat } from '../../../constants'

const SectionHd = ({toggleBulkOps, disabledCheck, bulkFlag}) => {
    return (
        <TableHead style={{ position: "sticky", top: '0', zIndex: '1' }}>
            <TableRow>
            <TableCell width="30">
                    <Checkbox
                        color="primary"
                        disabled={disabledCheck || (checkPermission('settings', 'config_mini_pack', 'D') ? false: true)}
                        className="checkbox-icon"
                        size="small"
                        onChange={(e) => toggleBulkOps(e.target.checked, 'all')}
                        checked={bulkFlag || false}
                    />
                </TableCell>
                <TableCell width='250px'>Section Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Keywords</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Updated By</TableCell>
                <TableCell width="55" align='right'>Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const SectionListing = ({ item, index, editSectionData, setDeleteModal, bulkOperation, toggleBulkOps }) => {
    return (
        <TableRow >
             <TableCell width="30">
                <Checkbox
                    disabled={checkPermission('settings', 'config_mini_pack', 'D') ? false: true}
                    color="primary"
                    className="checkbox-icon"
                    size="small"
                    onChange={(e) => { toggleBulkOps(e.target.checked, 'item', item?.id); e.stopPropagation() }}
                    checked={bulkOperation?.includes(item?.id)}
                />
            </TableCell>
            <TableCell>{item?.name || '--'}</TableCell>
            <TableCell>  <ReadMoreInline text={item?.description} /></TableCell>
            <TableCell>{ <ReadMorePopup data ={item?.keywords} title='Keywords' /> } </TableCell>
            <TableCell>
                <div>
                    {item?.created_by?.name || '--'}
                    <div style={{fontSize:'11px'}}>
                    {item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY, hh:mm a') : null}
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <div>
                    {item?.updated_by?.name || '--'}
                    <div style={{fontSize:'11px'}}>
                    {item?.updated_at ? moment(item?.updated_at).format('DD-MM-YYYY, hh:mm a') : null}
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <ul className='list-inline' style={{ float: 'right' }}>
                    {
                        checkPermission('settings', 'config_mini_pack', 'U')  ?
                            <li className='list-inline-item' style={{ cursor: 'pointer' }}>
                                <EditIcon onClick={() => editSectionData(item)} fontSize='small' color='primary' />
                            </li>
                            : null}
                    {
                        checkPermission('settings', 'config_mini_pack', 'D') ?
                            <li className='list-inline-item' style={{ cursor: 'pointer' }}>
                                <DeleteOutlineIcon onClick={() => setDeleteModal({ modal: true, data: item })} fontSize='small' color='secondary' />
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditSection = ({ addEditSection, open, setKeywordsCreated, toggleModal, sectionData, setSectionData, saveSectionData, error, setError, setAddKeywords }) => {
    // const [sectionData, setSectionDataMd] = useState(sectionData || {})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onFieldChange = (key, value, type) => {
        let checkArr= [...value]
        let newVal = checkArr.pop()
        let prevVal = checkArr
        if (key == 'keywords') {
            if (sectionData.keywords?.length <= value?.length && prevVal?.length && prevVal?.filter(item => item?.name.trim() == newVal?.inputValue.trim())?.length) {
                enqueueSnackbar('This folder name is already selected.', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
            else {
                // if (sectionData.keywords?.length <= value?.length) {
                //     // add flow
                //     setKeywordsCreated(value.map(item => ({ ...item, name: item?.inputValue })))
                //     setSectionData({ ...sectionData, [key]: value.map(item => ({ ...item, name: item?.inputValue })) })
                // }
                // else {
                    setKeywordsCreated(value.map(item => ({ ...item, name: item?.inputValue })))
                    setSectionData({ ...sectionData, [key]: value.map(item => ({ ...item, name: item?.inputValue })) })
                }
            // }
        }
        else{
            setSectionData({ ...sectionData, [key]: value })
        }
    }
    return (
        <Dialog
            open={addEditSection?.modal}
            onClose={() => { toggleModal() }}
            aria-labelledby="scroll-dialog-title"
            className='records-dataroom-modal minipack-section-modal'
        >
            <DialogTitle> {addEditSection?.data ? 'Edit' : 'Add'} Section </DialogTitle>
            <DialogContent dividers={true} style={{ width: "500px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="name"
                            fullWidth
                            margin="normal"
                            variant='outlined'
                            inputProps={{ maxLength: 50 }}
                            value={sectionData.name}
                            error={error?.name}
                            helperText={error?.name || ''}
                            onChange={(e) => onFieldChange('name', e.target.value)}
                            label="Name"
                            placeholder="Please enter Name"
                            onFocus={() => setError({ ...error, name: '' })}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className='select-template-btn' onClick={()=>setAddKeywords({modal:true, data:sectionData})}>
                            Select Keywords from Template
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{margin:'-15px 0px 0px'}}>
                        <AutocompleteCreatable
                            placeholder={sectionData.keywords?.length ?'':"Click on Select from Template or type to add new"}
                            options={[]}
                            value={sectionData.keywords || []}
                            paramsKey='keywords'
                            optionKey="name"
                            required={true}
                            multiple={true}
                            label="Keywords"
                            error={error?.keywords}
                            helperText={error?.keywords || ''}
                            resetErrorKey={() => setError({ ...error, keywords: '' })}
                            onFieldChange={(paramsKey, value, type) => onFieldChange('keywords', value, type)}
                        />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            fullWidth
                            margin="normal"
                            multiline={true}
                            rows={3}
                            variant='outlined'
                            inputProps={{ maxLength: 250 }}
                            value={sectionData.description}
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            label="Description"
                            placeholder="Please enter Description"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button size='small' variant='outlined' color="primary" onClick={() => toggleModal(false)}>Cancel</Button>
                <Button size='small' onClick={() => saveSectionData(sectionData)} variant="contained" color="primary">Save</Button>

            </DialogActions>
        </Dialog>
    )
}
export default function MiniPackSections(props) {
    const [addEditSection, setAddEditSection] = useState({})
    const [keywordsCreated, setKeywordsCreated] = useState([])
    const [sectionsList, setSectionsList] = useState([])
    const [deleteModal, setDeleteModal] = useState({})
    const [filter, setFilter] = useState({})
    const [sectionData, setSectionData] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [configId, setConfigId] = useState(false)
    const [error, setError] = useState(false)
    const [addKeywords, setAddKeywords] = useState(false)
    const [skeletonLoader, setSkeletonLoader] = useState(false)
    const [templateData, setTemplateData] = useState(false)
    const [bulkOperation, setBulkOperation] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        let configId = props.location?.search.split('?')[1]
        setConfigId(configId)
        getSectionsList()
    }, [])
    const getSectionsList = (payload, loaderType) => {
        if (loaderType == 'pageLoader') {
            setLoading(true)
        } else {
            setSkeletonLoader(true)
        }
        globalGetService(`records/v2/minipack/config/${props.location?.search.split('?')[1]}/sections/`, payload)
            .then(response => {
                setSkeletonLoader(false)
                setLoading(false)
                if (checkApiStatus(response)) {
                    if(payload){
                        delete payload['page']
                        delete payload['per_page']
                        setFilter(payload)
                    }
                    else{
                        setFilter({})
                    }
                    setSectionsList(response.data.data)
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    const editSectionData = (data) => {
        setKeywordsCreated(data?.keywords)
        setSectionData(data)
        setError({})
        setAddEditSection({ modal: true, data: data })
    }
    const saveSectionData = () => {
        let validateInput = {}
        validateInput = {
            ...validateInput,
            name: sectionData?.name ? '' : 'Please enter Name',
            keywords: sectionData?.keywords?.length ? '': 'Please enter Keywords',
        }
        if (Object.keys(validateInput).every(item => { return validateInput[item] === '' })) {
            let payload = Object.assign({} , sectionData)
            setLoading(true)
            if (payload?.id) {
                globalPutService(`records/v2/minipack/config/${configId}/sections/${payload?.id}/`, payload)
                .then(response => {
                    setKeywordsCreated([])
                    setLoading(false)
                    if (checkApiStatus(response)) {
                            setSectionData(false)
                            getSectionsList()
                            setAddEditSection(false)
                            setTemplateData(false)
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                        else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    })
            }
            else {
                globalPostService(`records/v2/minipack/config/${configId}/sections/`, payload)
                    .then(response => {
                        setKeywordsCreated([])
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            setSectionData(false)
                            getSectionsList()
                            setAddEditSection(false)
                            setTemplateData(false)
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                        else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    })
            }
        }
        else {
            setError(validateInput)
        }
    }
    const onDelete = (data) => {
        setLoading(true)
        if (data == 'bulk') {
            globalDeleteService(`records/v2/minipack/bulk-delete/config/${configId}/sections/`, { ids: bulkOperation })
                .then(response => {
                    setLoading(false)
                    setDeleteModal({ modal: false, data: null })
                    if (checkApiStatus(response)) {
                        getSectionsList()
                        setBulkOperation([])
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                    else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                })
        }
        else {
        globalDeleteService(`records/v2/minipack/config/${configId}/sections/${data?.id}/`)
            .then(response => {
                setLoading(false)
                setDeleteModal({ modal: false, data: null })
                if (checkApiStatus(response)) {
                    getSectionsList()
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
        }
    }
    const onSubmitKeyWdData =(data,payload)=>{
        let tempFolderData = data?.folderDetails.filter(item => item?.is_checked == true).map(item=> {return({...item, inputValue:item?.name}) })
        setSectionData(prevData=>({
            ...prevData, keywords: [...tempFolderData, ...keywordsCreated]
        }))
        setKeywordsCreated([...keywordsCreated,...tempFolderData])
        setAddKeywords(false)
        setTemplateData(data)
    }
    const toggleBulkOps = (flag, type, item) => {
        if (type == 'all') {
            setBulkOperation(flag ? sectionsList?.list?.map(item => item?.id) : [])
        }
        else {
            setBulkOperation(flag ? [...bulkOperation, item] : bulkOperation.filter(id => id !== item))
        }
    }
    let filterOptions = Object.assign({}, sectionFilterOptions )
    return (
        <div>
            {isLoading ? <PageLoader /> : null}
            {
                skeletonLoader ? <STableLoader count={7} /> :
                    <>
                        <span style={{ color: '#4050b5', cursor: 'pointer', position: 'relative', bottom: '10px', display: 'inline-flex' }} onClick={() => props.history.push('/settings/mini-pack')}>
                            <ArrowBack />
                            <span style={{ marginLeft: '3px' }}> {sectionsList?.config_details?.name}</span>
                        </span>
                    <Paper>
                        <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <FilterComponent
                                        filter={filter}
                                        filterMenu={filterOptions}
                                        getResponseBack={(applyFilter) => getSectionsList({ ...applyFilter, page: 1 }, 'pageLoader')}
                                    />
                                </Grid>
                            <Grid item xs={4}>
                            {
                                    bulkOperation?.length ?
                                        <Button variant='outlined' color='secondary' style={{ float: "right", margin: '5px' }} size='small' onClick={() => setDeleteModal({ modal: true, data: 'bulk' })}>
                                            Delete Mini Pack Section {`(${bulkOperation?.length})`}
                                        </Button>
                                        : null}
                                {
                                    checkPermission('settings', 'config_mini_pack', 'C') ?
                                        <Button variant='contained' color='primary' style={{ float: "right", margin: '5px' }} size='small' onClick={() => {setError({});setAddEditSection({ modal: true })}}>
                                            Add Section
                                        </Button>
                                        : null}
                            </Grid>
                        </Grid>
                        <Paper style={{ height: `${window.innerHeight - 210}px`, overflow: 'scroll', borderRadius: '0px' }}>
                            <Table className='mui-table-format'>
                                <SectionHd 
                                disabledCheck={sectionsList?.list?.length < 1}
                                toggleBulkOps={toggleBulkOps}
                                bulkFlag={(sectionsList?.list?.length != 0 && sectionsList?.list?.length == bulkOperation?.length) || false}
                                />
                                <TableBody>
                                    {
                                        sectionsList?.list?.map((item, index) =>
                                            <SectionListing
                                                bulkOperation={bulkOperation}
                                                toggleBulkOps={toggleBulkOps}
                                                setDeleteModal={setDeleteModal}
                                                item={item}
                                                index={index}
                                                onDelete={onDelete}
                                                editSectionData={editSectionData}
                                            />
                                        )}
                                </TableBody>
                            </Table>
                            {sectionsList?.list?.length ? null : <div> <EmptyCollection title="No Records Found" /></div>}
                        </Paper>
                        <Pagination
                            pagination={sectionsList.pagination}
                            onChangePage={(event, newPage) => { setBulkOperation([]); getSectionsList({ page: newPage + 1, per_page: sectionsList.pagination.per_page, ...filter }, 'pageLoader') }}
                            onChangeRowsPerPage={(event) => { setBulkOperation([]); getSectionsList({ page: 1, per_page: event.target.value, ...filter }, 'pageLoader') }}
                        />
                    </Paper>
                    </>
            }
            {
                addEditSection?.modal ?
                    <AddEditSection
                        setKeywordsCreated={setKeywordsCreated}
                        setError={setError}
                        error={error}
                        sectionData={sectionData}
                        saveSectionData={saveSectionData}
                        setSectionData={setSectionData}
                        setAddKeywords={setAddKeywords}
                        setAddEditSection={setAddEditSection}
                        addEditSection={addEditSection}
                        toggleModal={() => { setAddEditSection(false); setKeywordsCreated([]); setSectionData({});setTemplateData(false) }}
                    />
                    : null
            }
            {
                deleteModal?.modal ?
                    <DeletePopUp
                        modal={deleteModal.modal}
                        toggleModalFn={() => setDeleteModal({ modal: false, data: null })}
                        title="Delete Mini Pack Section"
                        content={<h4>Are you sure you want to delete?</h4>}
                        triggerEventFn={() => onDelete(deleteModal?.data)}
                    /> : null
            }
            {
                addKeywords?.modal ?
                <SectionFolderSelection 
                keywordsCreated={keywordsCreated}
                templateData={templateData}
                addKeywords={addKeywords}
                toggleModalFn={()=>{setAddKeywords(false);setKeywordsCreated([])}}
                submitWithTemplate={(data, payload)=>onSubmitKeyWdData(data, payload)}
                /> : null
            }
        </div>
    )
}

