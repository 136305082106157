import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices'
import { checkApiStatus, checkPermission } from '../../../utils'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { DeletePopUp, EmptyCollection, FilterComponent, PageLoader, Pagination } from '../../../shared_elements'
import { configFilterOptions } from '..'
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import { STableLoader } from '../../../shared_elements/loaders';
import ReadMoreInline from '../../../shared_elements/components/ReadMoreInline';
import moment from 'moment';
import { fieldDateFormat } from '../../../constants';
const ConfigHd = ({ toggleBulkOps, disabledCheck, bulkFlag }) => {
    return (
        <TableHead style={{ position: "sticky", top: '0', zIndex: '1' }}>
            <TableRow>
                <TableCell width="30">
                    <Checkbox
                        color="primary"
                        disabled={disabledCheck || (checkPermission('settings', 'config_mini_pack', 'D') ? false: true)}
                        className="checkbox-icon"
                        size="small"
                        onChange={(e) => toggleBulkOps(e.target.checked, 'all')}
                        checked={bulkFlag || false}
                    />
                </TableCell>
                <TableCell width='250px'>Mini Pack Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Updated By</TableCell>
                <TableCell width="75" align='right'>Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const ConfigListing = ({ item, setDeleteModal, editMiniPackData, bulkOperation, toggleBulkOps , setError}) => {
    return (
        <TableRow >
            <TableCell width="30">
                <Checkbox
                    disabled={checkPermission('settings', 'config_mini_pack', 'D') ? false: true}
                    color="primary"
                    className="checkbox-icon"
                    size="small"
                    onChange={(e) => { toggleBulkOps(e.target.checked, 'item', item?.id); e.stopPropagation() }}
                    checked={bulkOperation?.includes(item?.id)}
                />
            </TableCell>
            <TableCell>{item?.name}</TableCell>
            <TableCell style={{ maxWidth: '310px' }}>
                <ReadMoreInline text={item?.description} />
            </TableCell>
            <TableCell>{item?.status?.label || '--'}</TableCell>
            <TableCell>
                <div>
                    {item?.created_by?.name || '--'}
                    <div style={{fontSize:'11px'}}>
                    {item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY, hh:mm a') : null}
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <div>
                    {item?.updated_by?.name || '--'}
                    <div style={{fontSize:'11px'}}>
                    {item?.updated_at ? moment(item?.updated_at).format('DD-MM-YYYY, hh:mm a') : null}
                    </div>
                </div>
            </TableCell>

            {/* <TableCell>{item?.created_by?.name || '--'}</TableCell>
            <TableCell>{item?.updated_by?.name || '--'}</TableCell> */}
            <TableCell>
                <ul className='list-inline' style={{ float: 'right' }}>
                    <Tooltip title='Open Section' arrow placement='top'>
                    <li className='list-inline-item' style={{ cursor: 'pointer',marginRight:'3px' }}>
                        <OpenInNewIcon onClick={() => { window.open(`/settings/mini-pack-section/${item?.slug}?${item.id}`, "_self") }} fontSize='small' color='primary' />
                    </li>
                    </Tooltip>
                    {
                        checkPermission('settings', 'config_mini_pack', 'U') ?
                            <li className='list-inline-item' style={{ cursor: 'pointer', marginRight:'3px' }}>
                                <EditIcon onClick={(e) => { setError({}); editMiniPackData(item); e.stopPropagation() }} fontSize='small' color='primary' />
                            </li>
                            : null}
                    {
                        checkPermission('settings', 'config_mini_pack', 'D')  ?
                            <li className='list-inline-item' style={{ cursor: 'pointer' }}>
                                <DeleteOutlineIcon onClick={(e) => { setDeleteModal({ modal: true, data: item }); e.stopPropagation() }} fontSize='small' color='secondary' />
                            </li>
                            : null}

                </ul>
            </TableCell>
        </TableRow>

    )
}
const AddEditConfig = ({ addEditConfig, open, toggleModal, configData, error, saveConfigData, setError }) => {
    const [configDataMd, setConfigDataMd] = useState(configData || {})
    const onFieldChange = (key, value) => {
        setConfigDataMd({ ...configDataMd, [key]: value })
    }
    return (
        <Dialog
            open={addEditConfig?.modal}
            onClose={() => { toggleModal() }}
            aria-labelledby="scroll-dialog-title"
            className='records-dataroom-modal'
        >
            <DialogTitle> {addEditConfig?.data ? 'Edit' : 'Add'} Mini Pack Config </DialogTitle>
            <DialogContent dividers={true} style={{ width: "500px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            required
                            id="name"
                            fullWidth
                            margin="normal"
                            variant='outlined'
                            inputProps={{ maxLength: 50 }}
                            value={configDataMd.name}
                            error={error?.name}
                            helperText={error?.name || ''}
                            onChange={(e) => onFieldChange('name', e.target.value)}
                            label="Name"
                            placeholder="Please enter Name"
                            onFocus={() => setError({ ...error, name: '' })}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            style={{ paddingTop: "13px" }}
                            options={[{ label: 'Active', value: 1 }, { label: 'Inactive', value: 2 }]}
                            getOptionSelected={(option, value) => option.value === value.value}
                            getOptionLabel={option => option.label}
                            value={configDataMd?.status || null}
                            onChange={(e, data) => onFieldChange('status', data)}
                            renderInput={params => <TextField required error={error?.status} helperText={error?.status || ''} onFocus={() => setError({ ...error, status: '' })} variant='outlined' placeholder={"Please select Status"} size='small' {...params} margin="none" label='Status Type' fullWidth InputLabelProps={{ shrink: true }} />}
                        />  </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            fullWidth
                            margin="normal"
                            multiline={true}
                            rows={3}
                            variant='outlined'
                            inputProps={{ maxLength: 255 }}
                            value={configDataMd.description}
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            label="Description"
                            placeholder="Please enter Description"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button size='small' variant='outlined' color="primary" onClick={() => toggleModal(false)}>Cancel</Button>
                <Button size='small' onClick={() => saveConfigData(configDataMd)} variant="contained" color="primary">Save</Button>

            </DialogActions>
        </Dialog>
    )
}
export default function ConfigMiniPack() {
    const [configList, setConfigList] = useState([])
    const [filter, setFilter] = useState([])
    const [addEditConfig, setAddEditConfig] = useState({})
    const [configData, setConfigData] = useState({})
    const [deleteModal, setDeleteModal] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [skeletonLoader, setSkeletonLoader] = useState(false)
    const [error, setError] = useState({})
    const [bulkOperation, setBulkOperation] = useState([])
    useEffect(() => {
        getConfigList()
    }, [])
    const getConfigList = (payload, loaderType) => {
        if (loaderType == 'pageLoader') {
            setLoading(true)
        } else {
            setSkeletonLoader(true)
        }
        globalGetService(`records/v2/minipack/config/`, payload)
            .then(response => {
                if (payload) {
                    delete payload['page']
                    delete payload['per_page']
                    setFilter(payload)
                }
                else {
                    setFilter({})
                }
                setSkeletonLoader(false)
                setLoading(false)
                if (checkApiStatus(response)) {
                    setConfigList(response.data.data)
                }
            })
    }
    const onDelete = (data) => {
        setLoading(true)
        if (data == 'bulk') {
            globalDeleteService(`records/v2/minipack/bulk-delete/config/`, { ids: bulkOperation })
                .then(response => {
                    setLoading(false)
                    setDeleteModal({ modal: false, data: null })
                    if (checkApiStatus(response)) {
                        getConfigList()
                        setBulkOperation([])
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                    else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                })
        }
        else {
            globalDeleteService(`records/v2/minipack/config/${data?.id}/`)
                .then(response => {
                    setLoading(false)
                    setDeleteModal({ modal: false, data: null })
                    if (checkApiStatus(response)) {
                        getConfigList()
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                    else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                })
        }
    }
    let filterOptions = Object.assign({}, configFilterOptions)
    const editMiniPackData = (data) => {
        setAddEditConfig({ modal: true, data: data })
        setConfigData(data)
    }
    const saveConfigData = (payload) => {
        let validateInput = {}
        validateInput = {
            ...validateInput,
            name: payload?.name ? '' : 'Please enter Name',
            status: payload?.status ? '' : 'Please enter Status',
        }
        if (Object.keys(validateInput).every(item => { return validateInput[item] === '' })) {
            setLoading(true)
            if (payload?.id) {
                globalPutService(`records/v2/minipack/config/${payload?.id}/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            getConfigList()
                            setAddEditConfig(false)
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                        else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    })
            }
            else {
                globalPostService(`records/v2/minipack/config/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            getConfigList()
                            setAddEditConfig(false)
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                        else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    })
            }
        }
        else {
            setError(validateInput)
        }
    }
    const toggleBulkOps = (flag, type, item) => {
        if (type == 'all') {
            setBulkOperation(flag ? configList?.list?.map(item => item?.id) : [])
        }
        else {
            setBulkOperation(flag ? [...bulkOperation, item] : bulkOperation.filter(id => id !== item))
        }
    }
    return (
        <div>
            {isLoading ? <PageLoader /> : null}
            {
                skeletonLoader ? <STableLoader count={7} /> :
                    <Paper>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FilterComponent
                                    filter={filter}
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getConfigList({ ...applyFilter, page: 1 }, 'pageLoader')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    bulkOperation?.length ?
                                        <Button variant='outlined' color='secondary' style={{ float: "right", margin: '5px' }} size='small' onClick={() => setDeleteModal({ modal: true, data: 'bulk' })}>
                                            Delete Mini Pack Config {`(${bulkOperation?.length})`}
                                        </Button>
                                        : null}
                                {
                                    checkPermission('settings', 'config_mini_pack', 'C')  ?
                                        <Button variant='contained' color='primary' style={{ float: "right", margin: '5px' }} size='small' onClick={() => { setConfigData({});setError({}); setAddEditConfig({ modal: true }) }}>
                                            Add Mini Pack Config
                                        </Button>
                                        : null}
                            </Grid>
                        </Grid>
                        <Paper style={{ height: `${window.innerHeight - 174}px`, overflow: 'scroll', borderRadius: '0px' }}>

                            <Table className='mui-table-format'>
                                <ConfigHd
                                    disabledCheck={configList?.list?.length < 1}
                                    toggleBulkOps={toggleBulkOps}
                                    bulkFlag={(configList?.list?.length != 0 && configList?.list?.length == bulkOperation?.length) || false}
                                />
                                <TableBody>
                                    {
                                        configList?.list?.map(item => (
                                            <ConfigListing
                                            setError={setError}
                                                toggleBulkOps={toggleBulkOps}
                                                bulkOperation={bulkOperation}
                                                setDeleteModal={setDeleteModal}
                                                item={item}
                                                editMiniPackData={editMiniPackData}
                                                onDelete={onDelete}
                                            />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                            {configList?.list?.length ? null : <div> <EmptyCollection title="No Records Found" /></div>}
                        </Paper>
                        <Pagination
                            pagination={configList.pagination}
                            onChangePage={(event, newPage) => { setBulkOperation([]); getConfigList({ page: newPage + 1, per_page: configList.pagination.per_page, ...filter }, 'pageLoader') }}
                            onChangeRowsPerPage={(event) => { setBulkOperation([]); getConfigList({ page: 1, per_page: event.target.value, ...filter }, 'pageLoader') }}
                        />
                    </Paper>}
            {
                addEditConfig?.modal ?
                    <AddEditConfig
                        error={error}
                        setError={setError}
                        configData={configData}
                        saveConfigData={saveConfigData}
                        setConfigData={setConfigData}
                        setAddEditConfig={setAddEditConfig}
                        addEditConfig={addEditConfig}
                        toggleModal={() => { setAddEditConfig(false); setConfigData({}) }}
                    />
                    : null
            }
            {
                deleteModal?.modal ?
                    <DeletePopUp
                        modal={deleteModal.modal}
                        toggleModalFn={() => setDeleteModal({ modal: false, data: null })}
                        title="Delete Mini Pack Config"
                        content={<h4>Are you sure you want to delete?</h4>}
                        triggerEventFn={() => onDelete(deleteModal?.data)}
                    /> : null
            }
        </div>
    )
}
