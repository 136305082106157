import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { AssetHeader, AssetList, AssetTab, AddAssetDropdown, AssetPriorityModal, ChangeOCRPriority } from '../components'
import { globalGetService, globalExportService } from '../../../utils/globalApiServices';
import { checkPermission, checkApiStatus, getLocalStorageInfo, downloadFileType } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { Pagination, FilterComponent, ExportManu, PageLoader, EmptyCollection } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { Table, TableBody, Paper, Grid, Button, TextField } from '@material-ui/core';
import { assetFilterOptions, leaseStatus } from '../';
import config from '../../../config';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
const ActiveAssets = ({location}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [sortInfo, setSortInfo] = useState({sort:'', sort_by:''});
    const [filter, setFilter] = useState({asset_type: (config.env.key === "api" ? [483,484,465].includes(getLocalStorageInfo()?.defaultLessor?.id) : [532, 539, 540].includes(getLocalStorageInfo()?.defaultLessor?.id)) ? 2 : 0});
    const [activeAssets, setActiveAssets] = useState({list:[], pagination:{}});
    const [filterAircraftTypes, setFilterAircraftTypes] = useState([]);
    const [filterEngineTypes, setFilterEngineTypes] = useState([]);
    const [ocrPriority, setOcrPriority] = useState({modal:false, data:null});
    const [apuList, setApuList] = useState([]);
    useEffect(() => {
        getActiveAssets({...filter, per_page:50}, 'skeletonLoader');
        getAircraftTypes();
        getEngineTypes();
        getApuList();
    },[]);
    const getAircraftTypes = () => {
        globalGetService(`console/aircraft-types/`,{distinct:1})
        .then(response => {
            if(checkApiStatus(response)){
                setFilterAircraftTypes(response.data.data.aircraftTypes);
            }
        })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`,{distinct:1})
        .then(response => {
            if(checkApiStatus(response)){
                setFilterEngineTypes(response.data.data.engineTypes)
            }
        })
    }
    const getApuList = () => {
        globalGetService(`/console/apu-types/`)
        .then(response => {
            if(checkApiStatus(response)){
                setApuList(response.data.data.apu_types.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0))
            }
        })
    }
    const getActiveAssets = (query, loaderType) => {
        let queryParams = Object.assign({}, query);
        if(queryParams.asset_type === 0){
            delete queryParams['asset_type']
        }
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService('console/records-asset-list/?lite=1&records_asset_list=true&exclude_columns=records_details&records_details=1', window.location.href.includes('archived') ? {...queryParams, archive_status: 'True'} : {...queryParams})
        .then(response => {
            if(checkApiStatus(response)){
                setActiveAssets(response.data.data);
                delete query.page
                delete query.per_page
                delete query.sort
                delete query.sort_by
                setFilter(query);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        });
    }
    const onChangeTab = (event, newValue) => {
        getActiveAssets({per_page:50, asset_type: newValue} , 'pageLoader')
    }
    const createSortHandler = (key) => {
        let sortQuery = {sort:key};
        if(sortInfo.sort === key){
            sortQuery = { ...sortQuery, sort_by:sortInfo.sort_by === 'asc'?'desc':'asc'}
        }else{
            sortQuery = { ...sortQuery, sort_by:'asc'}
        }
        setSortInfo(sortQuery)
        getActiveAssets({...filter, ...sortQuery, page:1, per_page:activeAssets.pagination.per_page},'pageLoader');
    }
    const exportActiveAssets = (extension) => {
        setLoading(true);
        let queryParams = Object.assign({}, filter);
        if(queryParams.asset_type === 0){
            delete queryParams['asset_type']
        }
        globalExportService('console/records-asset-list/', window.location.href.includes('archived') ? {...queryParams,records_download:extension, archive_status: 'True'} : {...queryParams,records_download:extension})
        .then(response => {
            setLoading(false);
            if(getLocalStorageInfo().defaultLessor.id===442  && extension == 'xlsx'){
                enqueueSnackbar("Download link for Report will be sent to your registered email", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
             }else if (getLocalStorageInfo().defaultLessor.id===442  && extension == 'pdf') {
                downloadFileType(response.data, (`${getLocalStorageInfo().defaultLessor.name}_Records_Assets`), extension);
                enqueueSnackbar("File has been Exported Successfully", {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }else{
                downloadFileType(response.data, (`${getLocalStorageInfo().defaultLessor.name}_Records_Assets`), extension);
                enqueueSnackbar("File has been Exported Successfully", {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
            trackActivity('Asset List', {
                page_title: 'Asset Listing',
                event_type: 'Item Exported',
                event_desc: `Exported Assets from Assets List`,
                file_extension: extension
            })
        });
    }
    let filterOptions = Object.assign({}, assetFilterOptions);
    filterOptions = {
        ...filterOptions,
        aircraft_type:{
            ...filterOptions.aircraft_type,
            options:filterAircraftTypes
        },

        engine_type:{
            ...filterOptions.engine_type,
            options:filterEngineTypes
        },
        apu_type:{
            ...filterOptions.apu_type,
            options :apuList
        },
    }
    if(filter.asset_type === 1){
        delete filterOptions.engine_type
        delete filterOptions.apu_type
    }
    if(filter.asset_type === 2){
        delete filterOptions.aircraft_type
        delete filterOptions.apu_type
    }
    if(filter.asset_type === 3){
        delete filterOptions.aircraft_type
        delete filterOptions.engine_type
    }
    return(
        <section className='fleets-management'>
            <DeploymentMessage />
            <div>
                { skeletonLoader ? <STableLoader count={10} />:
                    <>  
                        <AssetTab 
                            assetCount={activeAssets.asset_count} 
                            pagination={activeAssets.pagination}
                            filter={filter}
                            onChangeTab={onChangeTab}
                            onFilterChange={(key, value) => setFilter({...filter, serial_number:value})}
                            onSearch={() => getActiveAssets({...filter, page:1, per_page:activeAssets?.pagination?.per_page||50}, 'pageLoader')}
                        />
                        
                        <Paper square style={{padding:'5px 10px'}}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={8} xs={8}>
                                    { ![4,5,6,7,8,9].includes(filter.asset_type)?
                                        <>
                                            {(()=>{
                                                let assetFilterObj = Object.assign({},filter)
                                                delete assetFilterObj['asset_type']
                                                return(
                                                    <FilterComponent 
                                                        filter={assetFilterObj} 
                                                        filterMenu={filterOptions}
                                                        getResponseBack={(applyFilter) => getActiveAssets({...applyFilter, asset_type:filter.asset_type, page:1, per_page:activeAssets.pagination.per_page}, 'pageLoader')}
                                                    />
                                                )
                                            })()}
                                        </>:null
                                    }
                                </Grid>
                                <Grid item md={4} xs={4}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        { !isMobile && checkPermission('records','data_room','OCR_P') ?
                                            <li className='list-inline-item'>
                                                <AssetPriorityModal />
                                            </li>:null
                                        }
                                        { !isMobile && (checkPermission('technical_specs', 'aircraft','C') || checkPermission('technical_specs', 'engine','C') || checkPermission('technical_specs', 'apu','C') || checkPermission('technical_specs', 'landing_gears','C') || checkPermission('technical_specs', 'propeller','C')) ?
                                            <li className='list-inline-item'>
                                                <AddAssetDropdown  />
                                            </li>:null
                                        }
                                        {activeAssets?.list?.length ?
                                            <li className='list-inline-item'>
                                                <ExportManu 
                                                    exportReportFn={(file) => exportActiveAssets(file.extension)}
                                                    files={[{title:'PDF', extension:'pdf'}, {title:'EXCEL', extension:'xlsx'}]}
                                                />
                                            </li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className='major-assemblies-list'>
                            <div style={{height: !activeAssets.list.length ? 'auto':`${window.innerHeight-300}px`,overflow:'auto'}}>
                                <Table className='mui-table-format' stickyHeader={true}>
                                    <AssetHeader 
                                        fleetType='active'
                                        filter={filter}
                                        sortInfo={sortInfo}
                                        createSortHandler={createSortHandler}
                                    />
                                    <TableBody>
                                        {activeAssets.list.map((item,index) =>
                                            <AssetList 
                                                fleetType='active'
                                                item={item}
                                                key={index}
                                                filter={filter}
                                                changeOcrPriority={() => setOcrPriority({modal:true, data:item})}
                                            />
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <Pagination 
                                pagination={activeAssets.pagination}
                                onChangePage={(event, newPage) => getActiveAssets({...filter, page:newPage+1, per_page: activeAssets.pagination.per_page, sort_by:sortInfo.sort_by, sort:sortInfo.sort}, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getActiveAssets({...filter, page:1, per_page: event.target.value, sort_by:sortInfo.sort_by, sort:sortInfo.sort}, 'pageLoader')}
                            />
                            {!activeAssets.list.length ? <div style={{textAlign:'center'}}><EmptyCollection title={'No records found'} /></div>:null}
                        </Paper>
                    </>
                }
            </div>
            { ocrPriority.modal ?
                <ChangeOCRPriority
                    ocrPriority={ocrPriority}
                    getResponseBack={() => console.log()}
                    toggleModalFn={() => setOcrPriority({modal:false, data:null})}
                />:null
            }
            
            { isLoading ? <PageLoader/>:null}
        </section>
    )
}
export default ActiveAssets;