import React, { useState } from 'react';
import { Grid, TextField, Paper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { getLocalStorageInfo, getFileSize } from '../../../utils';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
const HomeFilter = ({ allAssets, asset, onAssetChange, fleetFiles }) => {
  return (
    <div className="home-filter">
      <Grid container alignItems="center" spacing={3}>
       <DeploymentMessage />
        <Grid item xs={6} sm={6} md={6}>
          <ul className="list-inline flex-centered">
            <li className="list-inline-item">Filter By:</li>
            <li className="list-inline-item dd-width">
              <Autocomplete
                disableClearable={true}
                options={[{ unique_name: 'All Asset', id: 0 }, ...allAssets.sort((a, b) => a.unique_name !== b.unique_name ? a.unique_name < b.unique_name ? -1 : 1 : 0)]}
                getOptionLabel={option => option.unique_name}
                value={asset}
                onChange={(e, asset) => onAssetChange(asset)}
                renderInput={params => <TextField variant='outlined' {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
              />
            </li>
          </ul>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {
            fleetFiles ?
              <p className="total-size size-box-shadow">Total Size: <span>{getFileSize(fleetFiles.files_size)}</span></p> : null
          }
        </Grid>
      </Grid>
    </div>
  )
}
export default HomeFilter;
